import { createContext, PropsWithChildren, useContext } from "react";

export interface ServiceList {}

const ServiceContext = createContext<ServiceList | null>(null);

function useServices() {
  const services = useContext(ServiceContext);
  if (!services) throw new Error("No services");
  return services;
}

function Provider(props: PropsWithChildren<{ services: ServiceList }>) {
  return (
    <ServiceContext.Provider value={props.services}>
      {props.children}
    </ServiceContext.Provider>
  );
}

const Services = {
  use: useServices,
  Provider,
};

export default Services;
