/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMemo, useState } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import ConditionField from "src/components/fields/ConditionsField";
import Form from "src/components/fields/Form";
import SelectField from "src/components/fields/SelectField";
import TextField from "src/components/fields/TextField";
import Spacer from "src/components/Spacer";
import Environment from "src/utilities/Environment";
import { FrontError } from "src/utilities/getErrorMessage";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import useResponsive from "src/utilities/useResponsive";
import useSubmit from "src/utilities/useSubmit";

export default function ContactForm() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={Environment.string("REACT_APP_RECAPTCHA_CLIENT_KEY")}
      language={"fr"}
    >
      <ContactFormWithRecaptcha />
    </GoogleReCaptchaProvider>
  );
}

function ContactFormWithRecaptcha() {
  const { repository } = Services.use();

  const [firstname, setFirstname] = useState<string | null>(null);
  const [lastname, setLastname] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);

  const reasons = useMemo(
    () => [
      "J'ai un projet et j'ai besoin d'accompagnement",
      "Je voudrais participer à la dynamique de la filière",
      "Autre motif",
    ],
    []
  );
  const [reason, setReason] = useState<string | null>(null);
  const [details, setDetails] = useState<string | null>(null);
  const [conditions, setConditions] = useBooleanState(false);

  const stackDisplay = useResponsive({ 500: true, bigger: false });

  const gridCss = css({
    display: "grid",
    gridTemplateColumns: stackDisplay ? "1fr" : "1fr 1fr",
    gap: Theme.S,
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [onSubmit] = useSubmit(
    {
      fn: async () => {
        if (firstname === null)
          throw new FrontError("Remplissez tous les champs pour valider");
        if (lastname === null)
          throw new FrontError("Remplissez tous les champs pour valider");
        if (email === null)
          throw new FrontError("Remplissez tous les champs pour valider");
        if (phone === null)
          throw new FrontError("Remplissez tous les champs pour valider");
        if (reason === null)
          throw new FrontError("Remplissez tous les champs pour valider");
        if (details === null)
          throw new FrontError("Remplissez tous les champs pour valider");
        if (conditions !== true)
          throw new FrontError("Remplissez tous les champs pour valider");
        if (!executeRecaptcha)
          throw new Error("Cannot perform anti-bot validation");
        const recaptchaToken = await executeRecaptcha();
        await repository.createContactMessage({
          prenom: firstname,
          nom: lastname,
          email,
          telephone: phone,
          motif: reason,
          conditions,
          detail: details,
          recaptchaToken,
        });
        setReason(null);
        setDetails(null);
        setConditions.to(false);
      },
      successMessage: "Votre message a été enregistré !",
    },
    [
      firstname,
      lastname,
      email,
      phone,
      reason,
      details,
      conditions,
      executeRecaptcha,
    ]
  );

  return (
    <Form icon={"contact"} title="Contact" onSubmit={onSubmit}>
      <div css={gridCss}>
        <TextField
          required
          value={firstname}
          onChange={setFirstname}
          label="Prénom"
        />
        <TextField
          required
          value={lastname}
          onChange={setLastname}
          label="Nom"
        />
        <TextField required value={email} onChange={setEmail} label="Email" />
        <TextField
          required
          value={phone}
          onChange={setPhone}
          label="Téléphone"
        />
      </div>
      <Spacer />
      <SelectField
        required
        options={reasons}
        value={reason}
        onChange={setReason}
        label="Motif"
        keyExtractor={(i) => i}
        labelExtractor={(i) => i}
      />
      <Spacer />
      <TextField
        required
        textarea
        value={details}
        onChange={setDetails}
        label="Détaillez votre demande"
      />
      <Spacer />
      <ConditionField value={conditions} onChange={setConditions.to} />
      <Spacer />
    </Form>
  );
}
