/** @jsxImportSource @emotion/react */
import { DependencyList, Fragment, ReactNode } from "react";
import Typo from "src/components/Typo";
import Delayed, { useDelayed } from "./Delayed";
import Loading from "./Loading";

type DelayedViewProps<TValue> = {
  promise: Promise<TValue>;
  children: (value: TValue, reloading: boolean) => ReactNode;
  renderIdling?: () => ReactNode;
  renderPending?: () => ReactNode;
  renderRejected?: (error: any) => ReactNode;
  grow?: boolean;
  message?: string;
  persistValueDuringReload?: boolean;
};

export default function DelayedView<TValue>(props: DelayedViewProps<TValue>) {
  const {
    promise,
    children,
    grow,
    message,
    persistValueDuringReload = false,
  } = props;

  const renderIdling = props.renderIdling || (() => <Fragment />);
  const renderPending =
    props.renderPending || (() => <Loading grow={grow} message={message} />);
  const renderRejected =
    props.renderRejected ||
    ((err: any) => <Typo>Une erreur est survenue :/</Typo>);

  return (
    <Delayed
      {...props}
      renderIdling={renderIdling}
      renderPending={renderPending}
      renderRejected={renderRejected}
      persistValueDuringReload={persistValueDuringReload}
    />
  );
}

export function useDelayedString<TValue>(
  promise: Promise<TValue>,
  resolved: (v: TValue) => string,
  loading: string = "Chargement en cours...",
  persistValueDuringReload: boolean = false,
  deps?: DependencyList
) {
  return useDelayed(
    promise,
    resolved,
    () => "",
    () => loading,
    (err: any) => "Erreur :/",
    persistValueDuringReload,
    deps
  );
}
