/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import Icon, { IconProps } from "src/components/Icon";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";

type SpinnerProps = Omit<IconProps, "name" | "css" | "className">;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }

`;

function Spinner(props: SpinnerProps) {
  const spinnerCss = css({
    animation: `${rotate} 1s linear infinite`,
  });

  return <Icon name="loading" {...props} css={spinnerCss} />;
}

type LoadingProps = {
  message?: string;
  grow?: boolean;
  minHeight?: number;
};

const fade = keyframes`
  from {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  90% {
    opacity: 1;
  }


  to {
    opacity: 0;
  }

`;

export default function Loading(props: LoadingProps) {
  const { message = "Chargement en cours...", grow, minHeight } = props;

  const containerCss = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight,
    flexGrow: grow ? 1 : 0,
    animation: `${fade} 3s linear infinite`,
    padding: 20,
  });

  return (
    <div css={containerCss}>
      <Spinner />
      <Spacer />
      <Typo typo="account">{message}</Typo>
    </div>
  );
}
