/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import FooterBackground from "src/components/FooterBackground";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import Services from "src/utilities/Services";
import Articles from "./Articles";
import Carousel from "./Carousel";
import PointsDeVente from "./PointsDeVente";

export default function Home() {
  const { tracking } = Services.use();

  tracking.usePageTracking("Accueil");

  const homeCss = css();

  return (
    <Page>
      <div css={homeCss}>
        <Carousel />
        <Spacer scale={12} />
        {/* <AnciennesActualites css={css({ position: "relative", zIndex: 2 })} /> */}
        <PointsDeVente css={css({ position: "relative", zIndex: 1 })} />
        <Articles css={css({ position: "relative", zIndex: 1 })} />
        <FooterBackground />
      </div>
    </Page>
  );
}
