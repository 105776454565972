import { css } from "@emotion/react";

const clickableReset = css({
  color: "inherit",
  textDecoration: "none",
  display: "inline-block",
  border: "none",
  background: "transparent",
  fontFamily: "inherit",
  fontSize: "inherit",
  lineHeight: "inherit",
  padding: 0,
});

const link = css(clickableReset, {
  textDecoration: "underline",
});

const inputReset = css({
  border: "none",
  background: "transparent",
  width: "100%",
  outline: "none",
  textAlign: "left",
});

const Css = {
  clickableReset,
  inputReset,
  link,
};

export default Css;
