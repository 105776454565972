import axios from "axios";
import {
  AxiosCacheInstance,
  buildStorage,
  NotEmptyStorageValue,
  setupCache,
} from "axios-cache-interceptor";
import { set } from "lodash";
import qs from "qs";
import { DependencyList, useMemo } from "react";
import Authentication from "./Authentication";
import Model from "./Model";
import Strapi from "./Strapi";

export default class Repository {
  readonly api: AxiosCacheInstance;

  readonly storage = {
    set: (key: string, value: NotEmptyStorageValue) => {
      this.store[key] = { value: value, tags: [] };
    },
    find: (key: string) => {
      return this.store[key] ? this.store[key]["value"] : undefined;
    },
    remove: (key: string) => {
      delete this.store[key];
    },
  };

  private store: Record<
    string,
    { value: NotEmptyStorageValue; tags: Array<string> }
  > = {};

  constructor(url: string, private auth: Authentication) {
    this.api = setupCache(axios.create({ baseURL: url }), {
      storage: buildStorage(this.storage),
    });
    this.api.interceptors.request.use((config) => {
      const jwt = auth.searchJwt();
      if (jwt) {
        if (!config.headers) config.headers = {};
        set(config.headers, "authorization", `Bearer ${jwt}`);
      }
      return config;
    });
    this.api.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          axios.isAxiosError(error) &&
          error.response &&
          error.response.status === 401
        )
          auth.setJwt(null);
        return Promise.reject(error);
      }
    );
  }

  useData<O>(fn: (r: this) => Promise<O>, deps: DependencyList = []) {
    return useMemo(() => {
      return fn(this);
    }, deps);
  }

  // Storage

  //   setTags: (key: string, tags: Array<string>) => {
  //     if (this.store[key]) this.store[key].tags = tags;
  //   },
  //   searchKeys: (...tags: Array<string>) => {
  //     const entries = Object.entries(this.store);
  //     const out: Array<string> = entries
  //       .filter(([key, value]) => intersection(value.tags, tags).length >= 1)
  //       .map(([key]) => key);
  //     return out;
  //   },

  //   useData(fn){

  //   }

  // Auth

  async updateEmail(email: string) {
    const response = await this.api.post<Model.Me>(
      `/api/auth/send-email-confirmation`,
      { email }
    );
    return response.data;
  }

  async updatePassword(
    currentPassword: string,
    password: string,
    passwordConfirmation: string
  ) {
    const response = await this.api.post<Model.Me>(
      `/api/auth/change-password`,
      {
        currentPassword,
        password,
        passwordConfirmation,
      }
    );
    return response.data;
  }

  async confirmPasswordUpdate(
    code: string,
    password: string,
    passwordConfirmation: string
  ) {
    const response = await this.api.post<Model.Me>(`/api/auth/reset-password`, {
      code,
      password,
      passwordConfirmation,
    });
    return response.data;
  }

  // Me

  async getMe() {
    const response = await this.api.get<Model.Me>(`/api/users/me`);
    return response.data;
  }

  async updateMe(firstname: string, lastname: string, emailAddress: string) {
    const response = await this.api.patch<Model.Me>(`/api/users/me`, {
      firstname,
      lastname,
      username: emailAddress,
      email: emailAddress,
    });
    return response.data;
  }

  // News

  async getNewsInCarousel() {
    const params = qs.stringify(
      {
        populate: ["cover"],
        filters: { position: { $notNull: true } },
        sort: ["position"],
      },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Actualite>>(
      `/api/actualites?${params}`
    );
    return response.data.data;
  }

  async getAllNews() {
    const params = qs.stringify(
      {
        populate: ["cover"],
        filters: { position: { $null: true } },
        sort: ["createdAt"],
      },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Actualite>>(
      `/api/actualites?${params}`
    );
    return response.data.data;
  }

  async getOneNews(id: number) {
    const params = new URLSearchParams();
    params.append("populate", "cover");
    params.append("fields", "title");
    params.append("fields", "date");
    params.append("fields", "place");
    params.append("fields", "content");
    params.append("fields", "createdAt");
    params.append("fields", "updatedAt");
    const responses = await this.api.get<Strapi.ItemResponse<Model.Actualite>>(
      `/api/actualites/${id}`,
      { params }
    );
    return responses.data.data;
  }

  // Shops

  async getShops(query: ShopsQuery = {}) {
    const populate = ["products", "location"];
    const filters: any = { $and: [{ displayed: true }] };
    if (query.search) {
      filters.$and.push({
        $or: [
          { name: { $containsi: query.search } },
          { email: { $containsi: query.search } },
          { address: { $containsi: query.search } },
          { phone_number: { $containsi: query.search } },
          { website: { $containsi: query.search } },
        ],
      });
    }
    if (query.product) {
      filters.$and.push({ products: { id: { $eq: query.product } } });
    }
    if (query.area) {
      filters.$and.push({ location: { id: { $eq: query.area } } });
    }
    const params = qs.stringify(
      { populate, filters },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Shop>>(
      `/api/point-de-ventes?${params}`
    );
    return response.data.data;
  }

  async createShop(
    data: Omit<Model.Shop["attributes"], "products" | "location"> & {
      products: Array<number>;
      location: number;
      conditions: boolean;
      captcha: string;
    }
  ) {
    const res = await this.api.post<Strapi.ItemResponse<Model.Message>>(
      `/api/point-de-ventes`,
      { data: { ...data, displayed: false } }
    );
    return res;
  }

  // Revendeurs

  async getRevendeurs() {
    const params = qs.stringify(
      { sort: ["position"] },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Revendeur>>(
      `/api/revendeurs?${params}`
    );
    return response.data.data;
  }

  async getArticles() {
    const params = qs.stringify({}, { encodeValuesOnly: true });
    const response = await this.api.get<Strapi.ListResponse<Model.Article>>(
      `/api/articles?${params}`
    );
    return response.data.data;
  }

  async getArticle(id: string) {
    const params = qs.stringify({}, { encodeValuesOnly: true });
    const response = await this.api.get<Strapi.ItemResponse<Model.Article>>(
      `/api/articles/${id}?${params}`
    );
    return response.data.data;
  }

  // Products
  async getProducts() {
    const params = qs.stringify({}, { encodeValuesOnly: true });
    const response = await this.api.get<Strapi.ListResponse<Model.Product>>(
      `/api/products?${params}`
    );
    return response.data.data;
  }

  // Areas
  async getAreas() {
    const params = qs.stringify({}, { encodeValuesOnly: true });
    const response = await this.api.get<Strapi.ListResponse<Model.Area>>(
      `/api/areas?${params}`
    );
    return response.data.data;
  }

  // Forum topics

  async getTopics() {
    const params = qs.stringify(
      {
        sort: ["updatedAt:desc"],
      },
      { encodeValuesOnly: true }
    );
    const res = await this.api.get<Strapi.ListResponse<Model.Topic>>(
      `/api/sujet-du-forums?${params}`,
      { cache: false }
    );
    return res.data;
  }

  async getTopic(id: number | string) {
    const res = await this.api.get<Strapi.ItemResponse<Model.Topic>>(
      `/api/sujet-du-forums/${id}`
    );
    return res.data;
  }

  // Forum messages

  async getMessages(topic: string) {
    const params = qs.stringify(
      {
        populate: ["auteur"],
        filters: {
          sujet_du_forum: { id: { $eq: topic } },
          auteur: { id: { $notNull: true } },
        },
      },
      { encodeValuesOnly: true }
    );
    const res = await this.api.get<Strapi.ListResponse<Model.Message>>(
      `/api/messages-du-forum?${params}`
    );
    return res.data;
  }

  async createMessage(topic: string, message: string, userId: number) {
    const res = await this.api.post<Strapi.ItemResponse<Model.Message>>(
      `/api/messages-du-forum`,
      { data: { sujet_du_forum: topic, contenu: message } }
    );
    return res.data;
  }

  // Partners

  async getPartners() {
    const params = qs.stringify(
      { populate: ["logo"] },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Partner>>(
      `/api/partners?${params}`
    );
    return response.data.data;
  }

  // Events

  async getActions() {
    const params = qs.stringify(
      {
        filters: { end: { $gte: new Date().toISOString() } },
        sort: ["start"],
        populate: ["evenements", "etapes"],
      },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Action>>(
      `/api/actions-de-la-filiere?${params}`
    );
    return response.data.data;
  }

  async getUpcomingEvents() {
    const params = qs.stringify(
      {
        filters: { end: { $gte: new Date().toISOString() } },
        sort: ["start"],
      },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Event>>(
      `/api/evenements-pro?${params}`
    );
    return response.data.data;
  }

  async getPastEvents() {
    const params = qs.stringify(
      {
        filters: { end: { $lt: new Date().toISOString() } },
        sort: ["start:desc"],
      },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Event>>(
      `/api/evenements-pro?${params}`
    );
    return response.data.data;
  }

  // Info au porteur de projet

  async getCandidateInfos() {
    const params = qs.stringify(
      { sort: ["position"] },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<
      Strapi.ListResponse<Model.CanditadeInfo>
    >(`/api/info-au-porteur-de-projets?${params}`);
    return response.data.data;
  }

  async getCandidateQuestions() {
    const params = qs.stringify(
      { sort: ["position"] },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<
      Strapi.ListResponse<Model.CanditadeQuestion>
    >(`/api/questions-au-porteur-de-projet?${params}`);
    return response.data.data;
  }

  async createContactMessage(
    contact: Model.Contact["attributes"] & { recaptchaToken: string }
  ) {
    const response = await this.api.post<Strapi.ItemResponse<Model.Contact>>(
      `/api/prises-de-contact`,
      { data: contact }
    );
    return response.data.data;
  }

  async getPlants(params: PlantsQuery) {
    const q = [];
    if (!params.large) {
      if (params.nom) q.push({ nom: { $containsi: params.nom } });
      if (params.nomScientifique)
        q.push({
          nom_scientifique: { $containsi: params.nomScientifique },
        });
      if (params.application)
        q.push({ application: { $containsi: params.application } });
    } else {
      const words = `${params.nom || ""} ${params.nomScientifique || ""} ${
        params.application || ""
      }`
        .split(" ")
        .filter((w) => !!w);
      words.forEach((w) => {
        q.push({ nom: { $containsi: w } });
        q.push({ nom_scientifique: { $containsi: w } });
        q.push({ application: { $containsi: w } });
      });
    }

    const query = qs.stringify(
      { filters: { $or: q } },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Plant>>(
      `/api/plantes?${query}`
    );
    return response.data.data;
  }

  async getPlant(id: string) {
    const response = await this.api.get<Strapi.ItemResponse<Model.Plant>>(
      `/api/plantes/${id}`
    );
    return response.data.data;
  }

  async getResources() {
    const params = qs.stringify(
      { sort: ["position"], populate: ["fichiers"] },
      { encodeValuesOnly: true }
    );
    const response = await this.api.get<Strapi.ListResponse<Model.Ressource>>(
      `/api/ressources?${params}`
    );
    return response.data.data;
  }
}

type ShopsQuery = {
  search?: string | null;
  product?: number | null;
  area?: number | null;
};

type PlantsQuery = {
  nom: string | null;
  nomScientifique: string | null;
  application: string | null;
  large: boolean;
};
