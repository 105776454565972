/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { AssetInfos } from "src/utilities/Assets";
import PropsWithCss from "src/utilities/PropsWithCss";

type AssetProps = PropsWithCss<{
  asset: AssetInfos;
  width?: number | string;
}>;
export default function Asset(props: AssetProps) {
  const { asset } = props;

  const style = css({
    display: "block",
    objectFit: "contain",
    aspectRatio: `${asset.width} / ${asset.height}`,
    width: props.width || asset.width,
  });

  return (
    <img
      className={props.className}
      css={style}
      src={asset.path1x}
      srcSet={`${asset.path2x} 2x, ${asset.path3x} 3x`}
      alt={asset.alt}
    />
  );
}
