import { css, SerializedStyles } from "@emotion/react";

export const TYPO_LINE_HEIGHT = 1.5;

type TypoDescriptor = {
  weight: number;
  size: number;
  height: number;
  extra?: SerializedStyles;
};

function getStyles(
  descriptor: TypoDescriptor,
  font: "Signika" | "Neucha" | "Inter"
) {
  const { weight, size, height, extra } = descriptor;
  return css`
    font-family: "${font}", sans-serif;
    font-weight: ${weight};
    font-size: ${size}px;
    line-height: ${height}px;
    ${extra}
  `;
}

const headingDescriptor: TypoDescriptor = {
  weight: 800,
  size: 40,
  height: 60,
  extra: css({ textTransform: "uppercase" }),
};

const heading = getStyles(headingDescriptor, "Signika");

const subheadingDescriptor: TypoDescriptor = {
  weight: 800,
  size: 30,
  height: 40,
};

const subheading = getStyles(subheadingDescriptor, "Signika");

const sectionDescriptor: TypoDescriptor = {
  weight: 800,
  size: 20,
  height: 24,
  extra: css({ textTransform: "uppercase" }),
};

const section = getStyles(sectionDescriptor, "Inter");

const minorDescriptor: TypoDescriptor = {
  weight: 500,
  size: 13,
  height: 17,
};

const minor = getStyles(minorDescriptor, "Signika");

const bodyDescriptor: TypoDescriptor = {
  weight: 300,
  size: 18,
  height: 25,
};

const body = getStyles(bodyDescriptor, "Inter");

const subbodyDescriptor: TypoDescriptor = {
  weight: 400,
  size: 15,
  height: 18,
};

const subbody = getStyles(subbodyDescriptor, "Inter");

const subbodyBoldDescriptor: TypoDescriptor = {
  weight: 700,
  size: 15,
  height: 18,
};

const subbodyBold = getStyles(subbodyBoldDescriptor, "Inter");

const buttonDescriptor: TypoDescriptor = {
  weight: 400,
  size: 16,
  height: 18,
  extra: css({ textTransform: "uppercase", position: "relative", top: 2 }),
};

const button = getStyles(buttonDescriptor, "Neucha");

const introDescriptor: TypoDescriptor = {
  weight: 400,
  size: 24,
  height: 30,
  extra: css({
    textTransform: "uppercase",
    position: "relative",
    top: 4,
  }),
};

const intro = getStyles(introDescriptor, "Neucha");

const menuItemDescriptor: TypoDescriptor = {
  weight: 500,
  size: 22,
  height: 27,
};

const menuItem = getStyles(menuItemDescriptor, "Signika");

const accountDescriptor: TypoDescriptor = {
  weight: 400,
  size: 22,
  height: 24,
};

const account = getStyles(accountDescriptor, "Neucha");

const pillDescriptor: TypoDescriptor = {
  weight: 500,
  size: 14,
  height: 18,
};
const pill = getStyles(pillDescriptor, "Signika");

export type TypoName = keyof typeof Typos;

const Typos = {
  heading,
  minor,
  body,
  button,
  pill,
  subheading,
  section,
  menuItem,
  account,
  intro,
  subbody,
  subbodyBold,
};

export const TypoDescriptors: { [name in TypoName]: TypoDescriptor } = {
  heading: headingDescriptor,
  minor: minorDescriptor,
  body: bodyDescriptor,
  button: buttonDescriptor,
  pill: pillDescriptor,
  subheading: subheadingDescriptor,
  section: sectionDescriptor,
  menuItem: menuItemDescriptor,
  account: accountDescriptor,
  intro: introDescriptor,
  subbody: sectionDescriptor,
  subbodyBold: subbodyBoldDescriptor,
};

export default Typos;
