/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Theme from "src/utilities/Theme";

type SpacerProps = {
  size?: number;
  scale?: number;
  direction?: "both" | "vertical" | "horizontal";
  grow?: boolean;
  inline?: boolean;
};

function Spacer(props: SpacerProps) {
  const {
    size = Theme.S,
    scale = 1,
    direction = "both",
    grow = false,
    inline,
  } = props;
  const s = size * scale;
  const containerCss = css`
    width: ${direction === "vertical" ? 0 : s}px;
    height: ${direction === "horizontal" ? 0 : s}px;
    flex-grow: ${grow ? 1 : 0};
    flex-shrink: 0;
    display: ${inline ? "inline-block" : "block"};
  `;
  return <div css={containerCss} />;
}

export default Spacer;
