/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { without } from "lodash";
import { Key, useCallback, useMemo } from "react";
import Css from "src/utilities/Css";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";
import DelayedView from "src/utilities/components/DelayedView";
import Clickable from "../Clickable";
import Icon from "../Icon";
import Spacer from "../Spacer";
import Typo from "../Typo";
import Field, { useFieldController } from "./Field";
import fieldStyles from "./fieldStyles";

type CheckboxesFieldProps<TOption, TKey extends Key> = {
  label: string;
  value: Array<TKey>;
  options: Array<TOption> | Promise<Array<TOption>>;
  keyExtractor: (option: TOption) => TKey;
  labelExtractor: (option: TOption) => string;
  placeholder?: string;
  onChange: (value: Array<TKey>) => any;
  required?: boolean;
};

export default function CheckboxesField<TOption, TKey extends Key>(
  props: CheckboxesFieldProps<TOption, TKey>
) {
  const {
    value,
    placeholder,
    options,
    onChange,
    keyExtractor,
    labelExtractor,
    ...fieldProps
  } = props;

  const controller = useFieldController();

  const inputCss = css(Css.inputReset, Typos.body, fieldStyles.inputCss);

  const optionsPromise = useMemo(
    () => (options instanceof Promise ? options : Promise.resolve(options)),
    [options]
  );

  // const displayedValue = useDelayedString(
  //   optionsPromise,
  //   (options) => {
  //     const o = options.find((o) => keyExtractor(o) === value);
  //     if (!o) return "-";
  //     else return labelExtractor(o);
  //   },
  //   "Chargement en cours...",
  //   false,
  //   [value]
  // );

  const optionsCss = css({
    display: "flex",
    flexDirection: "column",
  });

  const optionCss = css(Css.clickableReset, {
    paddingBlock: Theme.S,
    paddingInline: Theme.S,
    textAlign: "inherit",
  });

  // const onSelectOption = useCallback(
  //   (key: TOption) => {
  //     onChange(keyExtractor(option));
  //     Dropdown.shutUp();
  //   },
  //   [onChange]
  // );

  // useEffect(() => {
  //   if (dropdown) controller.onFocus();
  //   else controller.onBlur();
  // }, [dropdown]);

  const onToggle = useCallback(
    (key: TKey) => {
      if (value.includes(key)) onChange(without(value, key));
      else onChange([...value, key]);
    },
    [value]
  );

  const isSelected = useCallback(
    (key: TKey) => {
      return value.includes(key);
    },
    [value]
  );

  const containerCss = css(inputCss, {
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
  });

  return (
    <Field {...fieldProps} controller={controller} stopPropagation>
      <DelayedView promise={optionsPromise}>
        {(options) => {
          return options.map((option) => {
            const key = keyExtractor(option);
            return (
              <Clickable
                key={key}
                css={containerCss}
                onClick={() => onToggle(key)}
              >
                <Icon
                  name={isSelected(key) ? "checkbox_checked" : "checkbox"}
                  color={Theme.colors.green5}
                />
                <Spacer scale={0.5} />
                <Typo>{labelExtractor(option)}</Typo>
              </Clickable>
            );
          });
        }}
      </DelayedView>
    </Field>
  );
}
