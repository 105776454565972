import Content from "src/components/Content";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import { RichContent } from "./Actualite";

export default function CharteForum() {
  return (
    <Page>
      <Intro
        title={`Charte éthique et règles de bonne conduite de la section "Echanges"`}
      />
      <Content>
        <Spacer scale={4} />
        <RichContent>{content}</RichContent>
        <Spacer scale={4} />
      </Content>
    </Page>
  );
}

const content = `
<h2>Bienvenue sur la section d’échanges entre professionnels de la filière PAPAM de La Réunion</h2>
<p>Si vous accédez à cette section « Échanges », c’est que vous faites partie des structures pilotes dans le cadre de l’initiative « Feuille de Route PAPAM » ou bien vous êtes susceptible d’apporter une plus-value majeure dans la structuration et la promotion de cette filière réunionnaise.</p>
<p>Cette section est une agora dont la condition est le respect mutuel, et dont le but est de doter les membres d’un outil pratique et convivial pour échanger (entre-autres) leurs bonnes pratiques, leurs idées de développement, les opportunités de collaboration, de montage de projets, de promotion, en lien avec la filière des plantes aromatiques, à parfum et médicinales de La Réunion.</p>
<p>Dans ce contexte, il est demandé à l’ensemble des membres d’adhérer à la charte éthique et de respecter l’intégralité des règles de bonne conduite.</p>

<h2>Règles de cette section « Échanges »</h2>

<h3>Bonnes pratiques</h3>
<p>Chaque membre est responsable de ses interventions, elles doivent être réfléchies et constructives pour les membres utilisateurs.</p>
<ul>
<li>Politesse : La contribution de chacun nous est chère et cette section est propice aux débats. Nous comptons sur vous pour les construire dans les règles de l’art : soyez courtois.</li>
<li>Respect : Chaque utilisateur est tenu de respecter les autres membres, pour que cet outil soit un endroit de libre expression des idées dans l’objectif commun.</li>
<li>Langage approprié : Pour la compréhension de tous, faites attention lors de la rédaction des messages, évitez la systématisation du langage SMS, limitez les répétitions des émoticônes, et limitez l’utilisation des mots en majuscules pour faciliter la lecture. Bien entendu, les mots grossiers n’ont pas leur place dans cette section.</li>
<li>Répétition : Avant de créer un nouveau post, veillez à ne pas générer des doublons, en vérifiant préalablement à travers le moteur de recherche intégré, qu’il n’existe aucune discussion similaire.</li>
<li>Se concentrer sur un thème : Eviter les hors sujet, suivez le thème abordé dans le post, pour gagner du temps, le vôtre et celui de votre correspondant.</li>
<ul>
<h3>Sont strictement interdits</h3>
<p>Pour rendre cette section utile et dans le respect de tous, certains comportements sont totalement proscrits et peuvent faire l’objet de sanctions :</p>
<ul>
<li>Ne sont en aucun cas tolérés les contenus injurieux, dénigrants, diffamatoires, menaçants ou plus généralement tout propos déplacé.</li>
<li>Ne s’agissant pas d’un espace de non-droit, les membres seront également tenus de respecter l’intégralité des conditions générales d’utilisation, mais également l’ensemble des lois françaises et européennes s’y rapportant.</li>
<li>Tout comportement se rapportant à du vandalisme, harcèlement ou « trolls » est interdit.</li>
<li>Tout message à des fins publicitaires ou commerciales qui ne sont pas explicitement nommées ou catégorisées dans une section spécifique est interdit.</li>
</ul>

<p>Tout propos de dénigrement à l’égard de l’éditeur du site sera supprimé.</p>

<h3>Les sanctions</h3>

<p>Tout comportement allant à l’encontre des règles préalablement citées, pourra faire l’objet d’une ou plusieurs des sanctions énumérées ci-après :</p>
<ul>
<li>Rappel par message des règles de bonne conduite</li>
<li>Suppression de messages</li>
<li>Proposition de suppression du compte auprès du comité d’exploitation du site.</li>
</ul>
`;
