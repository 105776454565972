import Content from "src/components/Content";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import { RichContent } from "./Actualite";

export default function MentionsLegales() {
  return (
    <Page>
      <Intro title="Mentions légales" />
      <Content>
        <Spacer />
        <RichContent>{content}</RichContent>
        <Spacer />
      </Content>
    </Page>
  );
}

const content = `
Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site  papampei.fr les informations suivantes : 

<h3>Ce site est exploité par</h3>
<p>L’exploitation de ce site est concédée aux pilotes de l’initiative du Département de La Réunion « Feuille de Route PAPAM » par une licence d’exploitation, dont l’éditeur fait partie.</p>

<h3>Ce site est édité par</h3>
<p>Le pôle de compétitivité Qualitropic, structure associative (loi 1901), est le mandataire pour l’administration technique et opérationnelle du site. Celle-ci en est donc la structure éditrice.
QUALITROPIC, le pôle de compétitivité des outre-mer</p>

<p>
Pépinière d’entreprises Le KUB<br />
6 rue Albert Lougnon<br />
Parc Technor<br />
97490 Sainte-Clotilde<br />
Tél. : +262 262 971 088<br />
</p>

<h3>Ce site est hébergé par</h3>

<p>Ce site est édité et hébergé par Clever Cloud SAS.</p>
<p>Clever Cloud SAS est une société par actions simplifiée au capital de 22 952 € immatriculée au Registre du Commerce et des Sociétés (RCS) de Nantes sous le numéro RCS Nantes B 524 172 699. Son siège social est situé au 3 rue de l’Allier, 44000 Nantes , La France. Numéro de TVA : FR 87 524 172 699</p>

`;
