import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Zones from "src/utilities/Zones";

export default function Professionnels() {
  const inRoute = Zones.useIsInRoute("Professionnels");

  let content: ReactNode;

  if (inRoute) {
    content = <Navigate to={Zones.getPath("Evenements")} replace />;
  } else {
    content = <Outlet />;
  }

  return content;
}
