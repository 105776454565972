/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { ReactNode } from "react";
import Css from "src/utilities/Css";
import Theme from "src/utilities/Theme";
import Clickable, { ClickableActionProps } from "./Clickable";
import Icon from "./Icon";
import Typo from "./Typo";

type ButtonContentProps = { label: string } | { icon: string };

type ButtonProps = {
  centered?: boolean;
  minor?: boolean;
  loading?: boolean;
  color?: string;
} & ClickableActionProps &
  ButtonContentProps;

export default function Button(props: ButtonProps) {
  const {
    minor,
    centered,
    color = Theme.colors.white,
    loading,
    ...clickable
  } = props;

  const isIcon = "icon" in props;

  let containerCss = css(Css.clickableReset, {
    position: "relative",
    backgroundColor: rgba(Theme.colors.green4, 0.7),
    borderRadius: 10000,
    transition: "background-color 100ms",
    color,
    "&:hover": {
      backgroundColor: rgba(Theme.colors.green4, 1),
    },
  });

  if (isIcon) {
    containerCss = css(containerCss, {
      width: 60,
      height: 60,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    });
  } else {
    containerCss = css(containerCss, {
      paddingInline: Theme.S * 3,
      paddingBlock: Theme.S,
    });
  }

  if (minor) {
    containerCss = css(containerCss, {
      background: "transparent",
      border: "1px solid lightgrey",
      "&:hover": {
        backgroundColor: rgba(Theme.colors.green4, 0.2),
      },
    });
  }

  const centeringCss = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  });

  const contentCss = css({
    opacity: loading ? 0 : 1,
    transition: "all 200ms",
  });

  const spinnerCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: loading ? 1 : 0,
    transition: "all 200ms",
    pointerEvents: "none",
  });

  let node: ReactNode = (
    <Clickable css={containerCss} {...clickable}>
      <div css={contentCss}>
        {"label" in props ? (
          <Typo center typo="button">
            {props.label}
          </Typo>
        ) : (
          <Icon name={props.icon} size={24} />
        )}
      </div>
      <div css={spinnerCss}>...</div>
    </Clickable>
  );

  if (centered) node = <div css={centeringCss}>{node}</div>;

  return node;
}
