/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, useCallback, useEffect, useState } from "react";
import Clickable from "src/components/Clickable";
import Content from "src/components/Content";
import Divider from "src/components/Divider";
import Icon from "src/components/Icon";
import Intro from "src/components/Intro";
import ReadMore, { ReadMoreButton } from "src/components/ReadMore";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import Css from "src/utilities/Css";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import DelayedView from "src/utilities/components/DelayedView";
import ListPlaceholder from "src/utilities/components/ListPlaceholder";
import useBooleanState from "src/utilities/useBooleanState";
import useResponsive from "src/utilities/useResponsive";
import { EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper/types";
import { RichContent } from "../Actualite";
import ContactForm from "./ContactForm";

export default function Integrer() {
  const { repository } = Services.use();

  Services.use().tracking.usePageTracking("Porteur de projet");

  const infos = repository.useData((r) => r.getCandidateInfos());
  const questions = repository.useData((r) => r.getCandidateQuestions());

  const [selection, setSelection] = useState<number | null>(null);

  const onQuestionClick = useCallback((q: Model.CanditadeQuestion) => {
    setSelection((current) => (current === q.id ? null : q.id));
  }, []);

  const slidesPerView = useResponsive({ 500: 1, 900: 2, bigger: 3 });

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  return (
    <div>
      <Intro
        intro="Porteur de projet"
        title="Découvrez et rejoignez la filière"
      />
      <Content>
        <Spacer scale={3} />
        <Typo typo="subheading">
          Informations à destination des porteurs de projet
        </Typo>
        <Spacer />
        <DelayedView promise={infos}>
          {(infos) => (
            <ListPlaceholder list={infos}>
              <Fragment>
                <Swiper
                  modules={[Navigation, EffectCoverflow]}
                  slidesPerView={slidesPerView}
                  spaceBetween={50}
                  onSwiper={setSwiper}
                >
                  {infos.map((info) => (
                    <SwiperSlide key={info.id}>
                      <CandidateInfoCard info={info} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                {swiper ? <SwiperNavigation swiper={swiper} /> : null}
              </Fragment>
            </ListPlaceholder>
          )}
        </DelayedView>
        <Spacer scale={3} />
        <Typo typo="subheading">FAQ</Typo>
        <Spacer />
        <DelayedView promise={questions}>
          {(questions) => (
            <ListPlaceholder list={questions}>
              <div>
                {questions.map((question) => (
                  <CandidateQuestion
                    key={question.id}
                    question={question}
                    selection={selection}
                    onClick={() => onQuestionClick(question)}
                  />
                ))}
              </div>
            </ListPlaceholder>
          )}
        </DelayedView>
        <Spacer scale={3} />
        <ContactForm />
        <Spacer scale={12} />
      </Content>
    </div>
  );
}

type CandidateInfoCardProps = {
  info: Model.CanditadeInfo;
};

function CandidateInfoCard(props: CandidateInfoCardProps) {
  const { info } = props;
  const { titre, detail } = info.attributes;

  const containerCss = css({
    // maxWidth: 300,
  });

  const cardCss = css({
    background: "white",
    marginBlock: 20,
    paddingInline: 20,
    borderRadius: 20,
  });

  const [unfolded, setUnfolded] = useBooleanState(false);

  return (
    <div css={containerCss}>
      <div css={cardCss}>
        <Spacer scale={2} />
        <Typo typo="intro">{titre}</Typo>
        <Spacer />
        <Divider size={50} />
        <Spacer />
        <ReadMore
          foldedHeight={300}
          unfolded={unfolded}
          toggler={
            <ReadMoreButton unfolded={unfolded} onToggle={setUnfolded.toggle} />
          }
        >
          <RichContent>{detail}</RichContent>
        </ReadMore>
        <Spacer />
      </div>
    </div>
  );
}

type CandidateQuestionProps = {
  question: Model.CanditadeQuestion;
  selection: number | null;
  onClick: () => any;
};

function CandidateQuestion(props: CandidateQuestionProps) {
  const { question: q, selection, onClick } = props;
  const { question, reponse } = q.attributes;

  const questionCss = css({
    paddingBlock: Theme.S,
    backgroundColor: rgba("white", 0.4),
    marginInline: 20,
    paddingInline: Theme.S,
    transition: "all 200ms",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: rgba("white", 1),
      marginInline: 10,
      marginBlock: 5,
      paddingInline: Theme.S + 10,
      borderRadius: 10,
    },
    "&:first-child": {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      marginTop: 5,
    },
    "&:last-child": {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      marginBottom: 5,
    },
  });

  const selectedQuestionCss = css(questionCss, {
    backgroundColor: rgba("white", 1),
    margin: 0,
    paddingInline: Theme.S + 20,
    paddingBlock: Theme.S * 1.5,
    borderRadius: 10,
    boxShadow: `0px 0px 40px ${rgba(Theme.colors.yellow, 0.2)}`,
    position: "relative",
    zIndex: 1,
    "&:hover": {
      margin: 0,
      paddingInline: Theme.S + 20,
      paddingBlock: Theme.S * 1.5,
    },
    "&:first-child": {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      marginTop: 5,
    },
    "&:last-child": {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      marginBottom: 5,
    },
  });

  return (
    <div
      css={selection === q.id ? selectedQuestionCss : questionCss}
      key={q.id}
      onClick={onClick}
    >
      <Typo typo="account">{question}</Typo>
      <ReadMore foldedHeight={0} unfolded={selection === q.id}>
        <Spacer />
        <Divider color={Theme.colors.green5} size={40} />
        <Spacer />
        <RichContent>{reponse}</RichContent>
      </ReadMore>
    </div>
  );
}

type SwiperNavigationProps = {
  swiper: SwiperClass;
};

function SwiperNavigation(props: SwiperNavigationProps) {
  const { swiper } = props;

  const onPrev = useCallback(() => {
    if (!swiper) return;
    swiper.slidePrev();
  }, [swiper]);

  const onNext = useCallback(() => {
    if (!swiper) return;
    swiper.slideNext();
  }, [swiper]);

  if (!swiper) return null;

  const containerCss = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  });

  const buttonCss = css(Css.clickableReset, {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    paddingLeft: 10,
    paddingBlock: 10,
    color: Theme.colors.yellow,
    transition: "opacity 200ms",
    "&:disabled": {
      opacity: 0.5,
    },
  });

  const [canSlidePrev, setCanSlidePrev] = useBooleanState(false);
  const [canSlideNext, setCanSlideNext] = useBooleanState(false);

  const checkPossibleSlides = useCallback(() => {
    setCanSlidePrev.to(!swiper.isBeginning);
    setCanSlideNext.to(!swiper.isEnd);
  }, [swiper]);

  useEffect(() => {
    checkPossibleSlides();
    swiper.on("resize", checkPossibleSlides);
    swiper.on("slideChange", checkPossibleSlides);
    return () => swiper.off("slideChange", checkPossibleSlides);
  }, [swiper]);

  if (!canSlidePrev && !canSlideNext) return null;

  return (
    <Fragment>
      <div css={containerCss}>
        <Clickable css={buttonCss} onClick={onPrev} disabled={!canSlidePrev}>
          <Icon typo="subheading" name="previous" />
        </Clickable>
        <Clickable css={buttonCss} onClick={onNext} disabled={!canSlideNext}>
          <Icon typo="subheading" name="next" />
        </Clickable>
      </div>
    </Fragment>
  );
}
