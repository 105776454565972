/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

export default function AutoHeightInput(
  props: React.HTMLProps<HTMLTextAreaElement>
) {
  const { value } = props;

  const containerCss = css({
    position: "relative",
  });

  const textCss = css({
    color: "transparent !important ",
    whiteSpace: "pre-wrap",
  });

  const textareaCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: "none !important",
    background: "transparent !important ",
    resize: "none",
  });

  return (
    <div className={props.className} css={containerCss}>
      <span css={textCss}>{value + "\u00a0"}</span>
      <textarea {...props} css={textareaCss} />
    </div>
  );
}
