import { Fragment, isValidElement, ReactNode } from "react";

export type IntersperseProps = {
  children: ReactNode | Array<ReactNode>;
  append?: boolean;
  prepend?: boolean;
  between: () => ReactNode;
};

export default function Intersperse(props: IntersperseProps) {
  const { prepend = false, append = false } = props;

  const children = Array.isArray(props.children)
    ? props.children
    : [props.children];

  return (
    <Fragment>
      {children
        .filter((c) => c !== null && c !== undefined && !isFragment(c))
        .map((c, i) => {
          return (
            <Fragment key={i}>
              {i > 0 || prepend ? props.between() : null}
              {c}
              {i === children.length - 1 && append ? props.between() : null}
            </Fragment>
          );
        })}
    </Fragment>
  );
}

function isFragment(node: ReactNode) {
  return isValidElement(node) && node.type === Fragment && !node.props.children;
}
