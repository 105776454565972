/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import Assets from "src/utilities/Assets";
import Theme from "src/utilities/Theme";
import useResponsive from "src/utilities/useResponsive";
import Asset from "./Asset";

export default function FooterBackground() {
  const mobile = useResponsive({
    800: true,
    bigger: false,
  });

  const wrapperCss = css({
    position: "relative",
  });

  const montagnesCss = css({
    width: "100%",
    marginTop: mobile ? 0 : Theme.S * -12,
    position: "relative",
  });

  const ref = useRef<HTMLDivElement>(null);

  const [intersaction, setIntersection] = useState<number>(0);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (!entry) return;
        setIntersection(entry.intersectionRatio);
      },
      { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  const sunCss = css({
    background: Theme.colors.yellow,
    width: `${interpolate(intersaction, 30, 20)}vw`,
    height: `${interpolate(intersaction, 30, 20)}vw`,
    borderRadius: "40vw",
    position: "absolute",
    bottom: `${interpolate(intersaction, 0, 50)}%`,
    left: `${interpolate(intersaction, 20, 10)}%`,
    opacity: `${interpolate(intersaction, 50, 100)}%`,
    // left: "-2vw",
    transition: "all 100ms",
  });

  return (
    <div css={wrapperCss} ref={ref}>
      <div css={sunCss} />
      <Asset asset={Assets.montagnes} css={montagnesCss} />
    </div>
  );
}

function interpolate(progress: number, from: number, to: number) {
  return from + progress * (to - from);
}
