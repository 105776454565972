/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, ReactNode } from "react";
import PropsWithCss from "src/utilities/PropsWithCss";
import Theme from "src/utilities/Theme";
import Divider from "./Divider";
import Spacer from "./Spacer";
import Typo from "./Typo";

export type PresentationProps = PropsWithCss<{
  title: string;
  content?: string;
  button?: ReactNode;
  dividerColor?: string;
}>;

export default function Presentation(props: PresentationProps) {
  const { title, content, button, dividerColor = Theme.colors.yellow } = props;

  const actualiteZone = css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  });

  return (
    <div css={actualiteZone} className={props.className}>
      <Typo typo="subheading">{title}</Typo>
      <Spacer />
      <Divider color={dividerColor} size={Theme.S * 4} />
      {content ? (
        <Fragment>
          <Spacer />
          <Typo>{content}</Typo>
        </Fragment>
      ) : null}
      {button ? (
        <Fragment>
          <Spacer scale={2} />
          {button}
        </Fragment>
      ) : null}
    </div>
  );
}
