import { EventEmitter } from "events";
import { nanoid } from "nanoid";
import { DependencyList, useEffect, useState } from "react";

const emitter = new EventEmitter();
emitter.setMaxListeners(0);

export default class Updater {
  private eventName: string;

  constructor() {
    this.eventName = nanoid();
  }

  update() {
    emitter.emit(this.eventName);
  }

  onUpdate(cb: () => void): () => void {
    emitter.on(this.eventName, cb);
    return () => emitter.off(this.eventName, cb);
  }

  useValue<TValue>(
    getter: (current: TValue | undefined) => TValue,
    deps: DependencyList = []
  ): TValue {
    const [value, setValue] = useState(getter(undefined));
    useEffect(() => {
      setValue(getter);
      const cb = () => setValue(getter);
      const unsub = this.onUpdate(cb);
      return () => unsub();
    }, deps);
    return value;
  }

  useReaction(reaction: () => void) {
    useEffect(() => {
      const unsub = this.onUpdate(reaction);
      return () => unsub();
    }, []);
  }
}
