/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { rgba } from "polished";
import { Fragment } from "react";
import Content from "src/components/Content";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import DelayedView from "src/utilities/components/DelayedView";
import Services from "src/utilities/Services";
import useResponsive from "src/utilities/useResponsive";
import Zones from "src/utilities/Zones";
import { RichContent } from "../Actualite";

export default function ArticlePage() {
  const { repository } = Services.use();
  const id = Zones.useParam("article");

  const article = repository.useData((r) => r.getArticle(id), []);

  const mobile = useResponsive({ 700: true, bigger: false });

  const sheetCss = css({
    background:
      "linear-gradient(126deg, rgba(255,255,255,1) 0%, rgba(232,232,232,1) 100%)",
    boxShadow: `0px 10px 100px ${rgba(0, 0, 0, 0.4)}`,
    padding: "5%",
    marginInline: mobile ? 0 : 20,
    position: "relative",
  });

  const subsheetCss = css({
    position: "absolute",
    backgroundColor: "white",
    boxShadow: `0px 10px 100px ${rgba(0, 0, 0, 0.2)}`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    transform: `rotate(-3deg)`,
    opacity: mobile ? 0 : 0.8,
  });

  const subsheet2Css = css({
    position: "absolute",
    backgroundColor: "white",
    boxShadow: `0px 10px 100px ${rgba(0, 0, 0, 0.2)}`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    transform: `rotate(2deg)`,
    opacity: mobile ? 0 : 0.6,
  });

  return (
    <Page>
      <DelayedView promise={article}>
        {(article) => (
          <Fragment>
            <Intro intro="Article" title={article.attributes.titre} />
            <Content>
              <Spacer scale={5} />
              <div css={sheetCss}>
                <div css={subsheetCss} />
                <div css={subsheet2Css} />
                <RichContent>{article.attributes.contenu}</RichContent>
              </div>
              <Spacer scale={5} />
              <div>
                <Typo center typo="minor">{`Rédigé le ${dayjs(
                  article.attributes.createdAt
                ).format("LLLL")}`}</Typo>
                {article.attributes.createdAt !==
                article.attributes.updatedAt ? (
                  <Typo center typo="minor">{`Dernière correction le ${dayjs(
                    article.attributes.updatedAt
                  ).format("LLLL")}`}</Typo>
                ) : null}
              </div>
              <Spacer scale={5} />
            </Content>
          </Fragment>
        )}
      </DelayedView>
    </Page>
  );
}
