/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { rgba } from "polished";
import { useMemo } from "react";
import Button from "src/components/Button";
import Content from "src/components/Content";
import Divider from "src/components/Divider";
import Intersperse from "src/components/Intersperse";
import ReadMore from "src/components/ReadMore";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import DelayedView from "src/utilities/components/DelayedView";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import useResponsive from "src/utilities/useResponsive";
import Zones from "src/utilities/Zones";

export default function ListeArticles() {
  const { repository } = Services.use();

  const containerCss = css({});

  const resultsCss = css({
    padding: Theme.S * 2,
  });

  const articles = repository.useData((r) => r.getArticles(), []);

  return (
    <Content>
      <div css={containerCss}>
        <div css={resultsCss}>
          <DelayedView promise={articles}>
            {(articles) => {
              return (
                <Intersperse between={() => <Spacer />}>
                  {articles.map((article) => {
                    return <Article key={article.id} article={article} />;
                  })}
                </Intersperse>
              );
            }}
          </DelayedView>
        </div>
      </div>
    </Content>
  );
}

type ArticleProps = {
  article: Model.Article;
};

function Article(props: ArticleProps) {
  const article = props.article;

  const containerCss = css({
    background: Theme.colors.white,
    borderRadius: 20,
    padding: Theme.S * 2,
    boxShadow: `0px 4px 8px ${rgba(Theme.colors.black, 0.08)}`,
  });

  const footerCss = useResponsive({
    600: css({
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
    }),
    bigger: css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
    }),
  });

  const textsCss = css({});

  const preview = useTextContent(article.attributes.contenu);

  return (
    <div css={containerCss}>
      <Typo typo="account">{article.attributes.titre}</Typo>
      <Spacer />
      <Divider strenght={1} />
      <Spacer />
      <ReadMore foldedHeight={100} gradientHeight={"0%"} unfolded={false}>
        <Typo typo="body">{preview}</Typo>
      </ReadMore>
      <Spacer />
      <div css={footerCss}>
        <div css={textsCss}>
          <Typo typo="minor">{`Rédigé le ${dayjs(
            article.attributes.createdAt
          ).format("LLLL")}`}</Typo>
          {article.attributes.createdAt !== article.attributes.updatedAt ? (
            <Typo typo="minor">{`Dernière correction le ${dayjs(
              article.attributes.updatedAt
            ).format("LLLL")}`}</Typo>
          ) : null}
        </div>
        <Spacer />
        <Button
          to={Zones.getPath("Article", {
            params: { article: article.id.toString() },
          })}
          label="Lire l'article"
        />
      </div>
    </div>
  );
}

function useTextContent(html: string) {
  return useMemo(() => {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText;
  }, [html]);
}
