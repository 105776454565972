/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import axios from "axios";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import "reset-css";
import "./App.css";
import Footer from "./components/Footer";
import HeaderDesktop from "./components/Header/HeaderDesktop";
import HeaderMobile from "./components/Header/HeaderMobile";
import Spacer from "./components/Spacer";
import useResponsive from "./utilities/useResponsive";

export default function App() {
  const Component = useResponsive({
    800: AppMobile,
    bigger: AppDesktop,
  });

  return <Component />;
}

function AppMobile() {
  const appCss = css({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  });

  return (
    <div css={appCss}>
      <HeaderMobile />
      <Outlet />
      <Footer />
    </div>
  );
}

function AppDesktop() {
  const appCss = css({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflow: "hidden",
  });

  return (
    <div css={appCss}>
      <HeaderDesktop />
      <Outlet />
      <Footer />
    </div>
  );
}

// SignUp

type SignUpResult = { jwt: string };

type SignUpProps = {
  onDone: (jwt: string) => any;
};

function SignUp(props: SignUpProps) {
  const { onDone } = props;

  const [signupEmail, setSignupEmail] = useState<string>("");
  const [signupPassword, setSignupPassword] = useState<string>("");

  const onSignUp = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const result = await axios.post<SignUpResult>(
        `${process.env.REACT_APP_API_URL}/api/auth/local/register`,
        {
          username: signupEmail,
          email: signupEmail,
          password: signupPassword,
        }
      );
      localStorage.setItem("token", result.data.jwt);
      onDone(result.data.jwt);
    },
    [signupEmail, signupPassword, onDone]
  );

  return (
    <Fragment>
      <h2>Créer un compte</h2>
      <form onSubmit={onSignUp}>
        <label htmlFor="signupEmail">Email</label>
        <br />
        <input
          id="signupEmail"
          type="email"
          value={signupEmail}
          onChange={(e) => setSignupEmail(e.target.value)}
        />
        <br />
        <br />
        <label htmlFor="signupEmail">Mot de passe</label>
        <br />
        <input
          id="signupEmail"
          type="password"
          value={signupPassword}
          onChange={(e) => setSignupPassword(e.target.value)}
        />
        <br />
        <br />
        <input type="submit" value="Créer un compte" />
      </form>
    </Fragment>
  );
}

// SignIn

type SignInResult = { jwt: string };

type SignInProps = {
  onDone: (jwt: string) => any;
};

function SignIn(props: SignInProps) {
  const { onDone } = props;

  const [signinEmail, setSignupEmail] = useState<string>("");
  const [signinPassword, setSignupPassword] = useState<string>("");

  const onSignUp = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const result = await axios.post<SignInResult>(
        `${process.env.REACT_APP_API_URL}/api/auth/local`,
        {
          identifier: signinEmail,
          password: signinPassword,
        }
      );
      onDone(result.data.jwt);
    },
    [signinEmail, signinPassword, onDone]
  );

  return (
    <Fragment>
      <h2>Se connecter</h2>
      <Spacer />
      <form onSubmit={onSignUp}>
        <label htmlFor="signinEmail">Email</label>
        <br />
        <input
          id="signinEmail"
          type="email"
          value={signinEmail}
          onChange={(e) => setSignupEmail(e.target.value)}
        />
        <br />
        <br />
        <label htmlFor="signinEmail">Mot de passe</label>
        <br />
        <input
          id="signinEmail"
          type="password"
          value={signinPassword}
          onChange={(e) => setSignupPassword(e.target.value)}
        />
        <br />
        <br />
        <input type="submit" value="Se connecter" />
      </form>
    </Fragment>
  );
}

// RecoverPassword

function RecoverPassword() {
  const [email, setEmail] = useState<string>("");

  const onForgotPassword = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        await axios.post<SignInResult>(
          `${process.env.REACT_APP_API_URL}/api/auth/forgot-password`,
          { email }
        );
        alert("Lien de réinitialisation envoyé par email");
      } catch (err) {
        alert("Pas de compte rattaché à cet email");
      }
    },
    [email]
  );

  return (
    <Fragment>
      <h2>Mot de passe oublié</h2>
      <form onSubmit={onForgotPassword}>
        <label htmlFor="signinEmail">Email</label>
        <br />
        <input
          id="signinEmail"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <input type="submit" value="Se connecter" />
      </form>
    </Fragment>
  );
}

type ResetPasswordResult = {
  jwt: string;
};

// ResetPassword

function ResetPassword() {
  const [params] = useSearchParams();
  const code = params.get("code");
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>("");

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        const output = await axios.post<ResetPasswordResult>(
          `${process.env.REACT_APP_API_URL}/api/auth/reset-password`,
          { code, password, passwordConfirmation: password }
        );
        // setJwt(output.data.jwt);
        navigate("/");
        alert("Mot de passe réinitialisé");
      } catch (err) {
        alert("Problème");
      }
    },
    [code, password]
  );

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <label htmlFor="newPassword">Nouveau mot de passe</label>
        <br />
        <input
          id="newPassword"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <input type="submit" value="Réinitialiser" />
      </form>
    </Fragment>
  );
}

// Account

type Me = {
  id: number;
  email: string;
};

type AccountProps = {
  jwt: string;
  onLogOut: () => any;
};

function AccountForm(props: AccountProps) {
  const { jwt, onLogOut } = props;

  const [me, setMe] = useState<Me | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await axios.get<Me>(
          `${process.env.REACT_APP_API_URL}/api/users/me`,
          { headers: { authorization: `Bearer ${jwt}` } }
        );
        setMe(result.data);
      } catch (err) {
        onLogOut();
      }
    })();
  }, [jwt]);

  if (me) {
    return (
      <Fragment>
        <h2>Bienvenue</h2>
        <br />
        Email : {me.email}
        <br />
        <br />
        <button type="button" onClick={onLogOut}>
          Se déconnecter
        </button>
      </Fragment>
    );
  } else {
    return <h2>Chargement en cours...</h2>;
  }
}
