/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, useState } from "react";
import { ReactNode } from "react-markdown/lib/react-markdown";
import Masonry from "react-responsive-masonry";
import Button from "src/components/Button";
import Clickable from "src/components/Clickable";
import Content from "src/components/Content";
import Divider from "src/components/Divider";
import CheckboxesField from "src/components/fields/CheckboxesField";
import ConditionField from "src/components/fields/ConditionsField";
import Form from "src/components/fields/Form";
import SelectField from "src/components/fields/SelectField";
import TextField from "src/components/fields/TextField";
import ReadMore, { ReadMoreButton } from "src/components/ReadMore";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { CaptchaProvider, useCaptchaChecker } from "src/utilities/Captcha";
import DelayedView from "src/utilities/components/DelayedView";
import ListPlaceholder from "src/utilities/components/ListPlaceholder";
import Css from "src/utilities/Css";
import { FrontError } from "src/utilities/getErrorMessage";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";
import useBooleanState from "src/utilities/useBooleanState";
import useDebounce from "src/utilities/useDebounced";
import useResponsive from "src/utilities/useResponsive";
import useSubmit from "src/utilities/useSubmit";

export default function ListePointsDeVente() {
  const { repository } = Services.use();

  const containerCss = css({});

  const resultsCss = css({
    padding: Theme.S * 2,
  });

  const filtersCss = css({
    display: "grid",
    gridTemplateColumns: useResponsive({ 900: "1fr", bigger: "1fr 1fr 1fr" }),
    gap: Theme.S * 2,
  });

  const masonryColumns = useResponsive({ 600: 1, 900: 2, bigger: 3 });

  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 1000);
  const [product, setProduct] = useState<number | null>(null);
  const [area, setArea] = useState<number | null>(null);

  const shops = repository.useData(
    (r) => r.getShops({ search: debouncedSearch, area, product }),
    [debouncedSearch, area, product]
  );
  const products = repository.useData((r) => r.getProducts());
  const areas = repository.useData((r) => r.getAreas());

  const revendeurs = repository.useData(
    (r) => r.getRevendeurs(),
    [debouncedSearch, area, product]
  );

  return (
    <Content>
      <div css={containerCss} id="points-de-vente">
        <div css={resultsCss}>
          <div css={filtersCss}>
            <TextField
              value={search}
              onChange={setSearch}
              label="Rechercher..."
              placeholder="Nom, téléphone, email, site internet"
              rightIcon="search"
            />
            <SelectField
              value={product}
              onChange={setProduct}
              label="Produits vendus"
              placeholder="Type de produit"
              options={products}
              keyExtractor={(p) => p.id}
              labelExtractor={(p) => p.attributes.label}
            />
            <SelectField
              value={area}
              onChange={setArea}
              label="Trier par :"
              placeholder="Secteur géographique"
              options={areas}
              keyExtractor={(p) => p.id}
              labelExtractor={(p) => p.attributes.label}
            />
          </div>
          <Spacer scale={3} />
          <DelayedView promise={shops}>
            {(shops, reloading) => {
              return (
                <Fragment>
                  {reloading ? (
                    <Typo center>Chargement en cours...</Typo>
                  ) : null}
                  <ListPlaceholder
                    ifEmpty="Il n'y a aucun point de vente qui correspond à ces critères"
                    list={shops}
                  >
                    <Masonry
                      columnsCount={masonryColumns}
                      gutter={Theme.S * 2 + "px"}
                    >
                      {shops.map((shop) => {
                        return (
                          <PointDeVente
                            key={shop.id}
                            pointDeVente={shop}
                            onSelectProduct={setProduct}
                            onSelectArea={setArea}
                          />
                        );
                      })}
                    </Masonry>
                  </ListPlaceholder>
                </Fragment>
              );
            }}
          </DelayedView>
          <Spacer scale={3} />
          <DelayedView promise={revendeurs}>
            {(revendeurs) => {
              return (
                <ListPlaceholder list={revendeurs} ifEmpty={<Fragment />}>
                  <Masonry
                    columnsCount={masonryColumns}
                    gutter={Theme.S * 2 + "px"}
                  >
                    {revendeurs.map((revendeur) => {
                      return (
                        <Revendeur key={revendeur.id} revendeur={revendeur} />
                      );
                    })}
                  </Masonry>
                </ListPlaceholder>
              );
            }}
          </DelayedView>
        </div>
      </div>
      <CaptchaProvider>
        <FormulairePointDeVente />
      </CaptchaProvider>
      <Spacer scale={3} />
    </Content>
  );
}

type PointDeVenteProps = {
  pointDeVente: Model.Shop;
  onSelectProduct: (p: number) => any;
  onSelectArea: (a: number) => any;
};

function PointDeVente(props: PointDeVenteProps) {
  const { onSelectProduct, onSelectArea } = props;
  const pointDeVente = props.pointDeVente;

  const containerCss = css({
    background: Theme.colors.white,
    borderRadius: 20,
    padding: Theme.S * 2,
    boxShadow: `0px 4px 8px ${rgba(Theme.colors.black, 0.08)}`,
  });

  const linkCss = css(Css.link, Typos.subbody, { display: "inline" });

  let productsNode: ReactNode = null;
  if (pointDeVente.attributes.products.data.length) {
    productsNode = (
      <div>
        <Typo typo="subbodyBold" color={Theme.colors.green5} inline>
          Produits :{" "}
        </Typo>
        {pointDeVente.attributes.products.data.map((p, i) => (
          <Fragment>
            {i > 0 ? ", " : ""}
            <span
              key={p.id}
              css={linkCss}
              onClick={() => onSelectProduct(p.id)}
            >
              {p.attributes.label}
            </span>
          </Fragment>
        ))}
      </div>
    );
  }

  let araeNode: ReactNode = null;
  if (pointDeVente.attributes.location) {
    const area = pointDeVente.attributes.location;
    araeNode = (
      <div>
        <Typo typo="subbodyBold" color={Theme.colors.green5} inline>
          Secteur géographique :{" "}
        </Typo>
        <Typo typo="subbody" inline>
          <Clickable css={Css.link} onClick={() => onSelectArea(area.data.id)}>
            {pointDeVente.attributes.location.data.attributes.label}
          </Clickable>
        </Typo>
      </div>
    );
  }

  return (
    <div css={containerCss}>
      <Typo typo="intro">{pointDeVente.attributes.name}</Typo>
      <Spacer scale={1} />
      <Divider strenght={1} />
      <Spacer scale={1} />
      <Typo typo="subbodyBold">{pointDeVente.attributes.phone_number}</Typo>
      <Spacer scale={1} />
      <Typo typo="subbody">{pointDeVente.attributes.address}</Typo>
      {pointDeVente.attributes.hours ? (
        <Fragment>
          <Spacer scale={1} />
          <Typo typo="subbodyBold" color={Theme.colors.green5}>
            Horaires d'ouverture
          </Typo>
          <Typo typo="subbody">{pointDeVente.attributes.hours}</Typo>
        </Fragment>
      ) : null}
      {pointDeVente.attributes.website ? (
        <Fragment>
          <Spacer scale={1} />
          <Clickable
            href={pointDeVente.attributes.website}
            target="_blank"
            css={Css.link}
          >
            <Typo typo="subbody">{pointDeVente.attributes.website}</Typo>
          </Clickable>
        </Fragment>
      ) : null}
      <Spacer scale={1} />
      {productsNode}
      {araeNode}
    </div>
  );
}

type RevendeurProps = {
  revendeur: Model.Revendeur;
};

function Revendeur(props: RevendeurProps) {
  const { revendeur } = props;

  const containerCss = css({
    background: Theme.colors.white,
    borderRadius: 20,
    padding: Theme.S * 2,
    boxShadow: `0px 4px 8px ${rgba(Theme.colors.black, 0.08)}`,
  });

  const [more, setMore] = useBooleanState(false);

  return (
    <div css={containerCss}>
      <Typo typo="intro">{revendeur.attributes.titre}</Typo>
      <Spacer scale={1} />
      <Divider strenght={1} />
      <Spacer scale={1} />
      <ReadMore
        unfolded={more}
        foldedHeight={200}
        toggler={<ReadMoreButton unfolded={more} onToggle={setMore.toggle} />}
      >
        <Typo>{revendeur.attributes.description}</Typo>
      </ReadMore>
      <Spacer scale={1} />
      {revendeur.attributes.lien ? (
        <Button
          centered
          label="En savoir plus"
          href={revendeur.attributes.lien}
          target="_blank"
        />
      ) : null}
    </div>
  );
}

function FormulairePointDeVente() {
  const { repository } = Services.use();

  const stackDisplay = useResponsive({ 500: true, bigger: false });

  const gridCss = css({
    display: "grid",
    gridTemplateColumns: stackDisplay ? "1fr" : "1fr 1fr",
    gap: Theme.S,
  });

  const [name, setName] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [hours, setHours] = useState<string | null>(null);
  const [website, setWebsite] = useState<string | null>(null);
  const [products, setProducts] = useState<Array<number>>([]);
  const [area, setArea] = useState<number | null>(null);
  const [conditions, setConditions] = useBooleanState(false);
  const captchaChecker = useCaptchaChecker();

  const [onSubmit, submitting] = useSubmit(
    {
      fn: async () => {
        if (!name) throw new FrontError("Saisissez un nom de point de vente");
        if (!address)
          throw new FrontError("Saisissez l'adresse du point de vente");
        if (!products.length)
          throw new FrontError("Choisissez au moins un type de produit");
        if (!area) throw new FrontError("Définissez le secteur géographique");
        const captcha = await captchaChecker();
        await repository.createShop({
          name,
          address,
          products,
          location: area,
          website,
          phone_number: phone,
          hours,
          email,
          conditions,
          captcha,
        });
      },
      successMessage:
        "Votre demande est enregistrée et sera traitée dans les plus brefs délais !",
    },
    [
      name,
      address,
      email,
      phone,
      hours,
      website,
      products,
      area,
      conditions,
      captchaChecker,
    ]
  );

  const allAreas = repository.useData((r) => r.getAreas());
  const allProducts = repository.useData((r) => r.getProducts());

  return (
    <Form
      icon={"contact"}
      title="Faire partie de la liste des points de vente"
      onSubmit={onSubmit}
    >
      <TextField
        value={name}
        onChange={setName}
        label="Nom du point de vente"
        required
      />
      <Spacer />
      <TextField
        value={address}
        onChange={setAddress}
        label="Adresse postale"
        required
      />
      <Spacer />
      <div css={gridCss}>
        <TextField value={email} onChange={setEmail} label="Adresse email" />
        <TextField value={phone} onChange={setPhone} label="Téléphone" />
        <TextField
          value={hours}
          onChange={setHours}
          label="Horaires d'ouverture"
        />
        <TextField
          value={website}
          onChange={setWebsite}
          label="Site internet"
        />
      </div>
      <Spacer />
      <CheckboxesField
        label="Produits vendus"
        options={allProducts}
        value={products}
        onChange={setProducts}
        keyExtractor={(o) => o.id}
        labelExtractor={(o) => o.attributes.label}
        required
      />
      <Spacer />
      <SelectField
        label="Situation géographique"
        options={allAreas}
        value={area}
        onChange={setArea}
        keyExtractor={(a) => a.id}
        labelExtractor={(a) => a.attributes.label}
        required
      />
      <Spacer />
      <ConditionField value={conditions} onChange={setConditions.to} />
    </Form>
  );
}
