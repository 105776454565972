/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "src/components/Button";
import Content from "src/components/Content";
import Form from "src/components/fields/Form";
import TextField from "src/components/fields/TextField";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import DelayedView from "src/utilities/components/DelayedView";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import useSubmit from "src/utilities/useSubmit";
import Zones from "src/utilities/Zones";

export default function Espace() {
  const { repository, authentication } = Services.use();
  const navigate = useNavigate();

  const me = useMemo(() => repository.getMe(), []);

  return (
    <Page restricted>
      <Intro title="Mon compte Papam Péï" />
      <DelayedView promise={me}>{(me) => <EspaceView me={me} />}</DelayedView>
      <Spacer scale={12} />
    </Page>
  );
}

type EspaceViewProps = {
  me: Model.Me;
};

function EspaceView(props: EspaceViewProps) {
  const { me } = props;

  const { repository, authentication } = Services.use();
  const navigate = useNavigate();

  const gridCss = css({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: Theme.S * 2,
  });

  const [firstname, setFirstName] = useState<string | null>(me.firstname);
  const [lastname, setLastName] = useState<string | null>(me.lastname);

  const onLogout = useCallback(() => {
    navigate(Zones.getPath("Home"));
    authentication.setJwt(null);
  }, []);

  const onSubmitInfos = useCallback(async () => {
    if (!firstname) throw new Error();
    if (!lastname) throw new Error();
    // await repository.updateMe(firstname, lastname, emailAddress);
  }, [firstname, lastname]);

  const [emailAddress, setEmailAddress] = useState<string | null>(me.username);

  const [onChangeEmail] = useSubmit(
    {
      fn: async () => {
        if (!emailAddress) throw new Error();
        await repository.updateEmail(emailAddress);
      },
    },
    [emailAddress]
  );

  const [currentPassword, setCurrentPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [newPasswordAgain, setNewPasswordAgain] = useState<string | null>(null);

  const [onChangePassword] = useSubmit(
    {
      fn: async () => {
        if (!currentPassword) throw new Error();
        if (!newPassword) throw new Error();
        if (!newPasswordAgain) throw new Error();
        await repository.updatePassword(
          currentPassword,
          newPassword,
          newPasswordAgain
        );
        setCurrentPassword(null);
        setNewPassword(null);
        setNewPasswordAgain(null);
      },
      successMessage: "Votre mot de passe a été modifié",
    },
    [currentPassword, newPassword, newPasswordAgain]
  );

  return (
    <Content>
      <Spacer scale={6} />
      <div css={gridCss}>
        <Form icon="user" title="Mes informations" onSubmit={onSubmitInfos}>
          <TextField label="Prénom" value={firstname} onChange={setFirstName} />
          <Spacer />
          <TextField
            label="Nom de famille"
            value={lastname}
            onChange={setLastName}
          />
          <Spacer />
        </Form>
        {/* <Form icon="key" title="Adresse email" onSubmit={onChangeEmail}>
          <Typo typo="minor" center css={css({ opacity: 0.7 })}>
            Cette adresse email est utilisée pour vous connecter à la plateforme
            PapamPéï. Si vous perdez votre mot de passe, cette adresse sera
            utilisée pour vous envoyer les instructions pour définir un nouveau
            mot de passe.
          </Typo>
          <Spacer scale={2} />
          <TextField
            label="Nouvelle adresse email"
            value={emailAddress}
            onChange={setEmailAddress}
            type="email"
          />
        </Form> */}
        <Form icon="key" title="Mot de passe" onSubmit={onChangePassword}>
          <Typo center>
            Changez votre mot de passe de connexion à la plateforme en quelques
            secondes
          </Typo>
          <Spacer scale={2} />
          <TextField
            label="Mot de passe actuel"
            value={currentPassword}
            onChange={setCurrentPassword}
            type="password"
          />
          <Spacer />
          <TextField
            label="Nouveau mot de passe"
            value={newPassword}
            onChange={setNewPassword}
            type="password"
          />
          <Spacer />
          <TextField
            label="Ressaisissez le nouveau mot de passe une seconde fois"
            value={newPasswordAgain}
            onChange={setNewPasswordAgain}
            type="password"
          />
          <Spacer />
        </Form>
      </div>
      <Spacer scale={3} />
      <Button
        centered
        label="Se déconnecter"
        onClick={onLogout}
        minor
        color={Theme.colors.green5}
      />
    </Content>
  );
}
