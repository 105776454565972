/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, useCallback, useMemo } from "react";
import PropsWithCss from "src/utilities/PropsWithCss";
import Theme from "src/utilities/Theme";
import Updater from "src/utilities/Updater";
import randomId from "src/utilities/randomId";
import Icon from "../Icon";
import Spacer from "../Spacer";
import Typo from "../Typo";

type FieldProps = PropsWithCss<{
  label: string;
  rightIcon?: string;
  dropdown?: ReactNode;
  stopPropagation?: boolean;
  controller: FieldController;
  children: ReactNode;
  required?: boolean;
}>;

export default function Field(props: FieldProps) {
  const {
    label,
    controller,
    rightIcon,
    dropdown,
    children,
    className,
    stopPropagation,
    required,
  } = props;

  const focused = controller.useFocus();

  const color = focused ? Theme.colors.green3 : Theme.colors.lightgrey;

  const containerCss = css({
    width: "100%",
    position: "relative",
    color,
  });

  const visibleCss = css({
    background: "white",
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: `1px solid ${color}`,
  });

  const inputCss = css({
    flex: 1,
    color: "inherit",
  });

  const rightIconCss = css({
    paddingBlock: Theme.S / 2,
    paddingInline: Theme.S,
  });

  const labelCss = css({
    display: "block",
  });

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (stopPropagation) e.stopPropagation();
    },
    [stopPropagation]
  );

  return (
    <div css={containerCss} onClick={onClick}>
      <label css={labelCss} htmlFor={controller.id}>
        <Typo typo="subbody">
          {label}
          {required ? (
            <Typo typo="subbody" inline color="red">
              *
            </Typo>
          ) : null}
        </Typo>
      </label>
      <Spacer scale={0.25} />
      <div css={visibleCss}>
        <div css={inputCss} className={className}>
          {children}
        </div>
        {rightIcon ? (
          <div css={rightIconCss}>
            <Icon name={rightIcon} size={16} />
          </div>
        ) : null}
        {dropdown}
      </div>
    </div>
  );
}

class FieldController {
  private updater = new Updater();
  readonly id = randomId();
  private focused: boolean = false;

  useFocus() {
    return this.updater.useValue(() => this.focused);
  }

  onFocus() {
    this.focused = true;
    this.updater.update();
  }

  onBlur() {
    this.focused = false;
    this.updater.update();
  }
}

export function useFieldController() {
  return useMemo(() => new FieldController(), []);
}
