/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, PropsWithChildren, ReactNode } from "react";
import PropsWithCss from "../PropsWithCss";

type BackgroundedProps = PropsWithCss<
  PropsWithChildren<{
    backgrounds: ReactNode | Array<ReactNode>;
  }>
>;

export default function Backgrounded(props: BackgroundedProps) {
  const containerCss = css({
    position: "relative",
    "& > *": {
      position: "relative",
      zIndex: 2,
    },
    "& > .backgrounds": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    },
  });

  const contentCss = css({
    position: "relative",
    zIndex: 2,
  });

  const arr = Array.isArray(props.backgrounds)
    ? props.backgrounds
    : [props.backgrounds];

  return (
    <div css={containerCss} className={props.className}>
      <div className="backgrounds">
        {arr.map((b, i) => (
          <Fragment key={i}>{b}</Fragment>
        ))}
      </div>
      {props.children}
    </div>
  );
}
