/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Asset from "src/components/Asset";
import Button from "src/components/Button";
import Content from "src/components/Content";
import { PhotoStack, PhotoStackItem } from "src/components/PhotoStack";
import Presentation from "src/components/Presentation";
import Assets from "src/utilities/Assets";
import PropsWithCss from "src/utilities/PropsWithCss";
import Theme from "src/utilities/Theme";
import Zones from "src/utilities/Zones";
import useResponsive from "src/utilities/useResponsive";

export default function PointsDeVente(props: PropsWithCss) {
  const mobile = useResponsive({
    800: true,
    bigger: false,
  });

  const wrapperCss = css({
    position: "relative",
    paddingBlock: Theme.S * 6,
  });

  const backgroundCss = css({
    position: "absolute",
    top: Theme.S * -10,
    bottom: mobile ? 0 : Theme.S * 10,
    left: 0,
    right: 0,
    background: Theme.colors.yellow,
    zIndex: -1,
  });

  const contentCss = mobile
    ? css({
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: Theme.S * 2,
      })
    : css({
        display: "grid",
        gridTemplateColumns: "1fr 40%",
        gap: Theme.S * 6,
      });

  const photoStackCss = css({
    height: mobile ? "30vh" : "70vh",
  });

  const feuilleCss = css({
    position: "absolute",
    bottom: Theme.S * -10,
    right: Theme.S * -3,
    width: 240,
  });

  const textsCss = css({
    paddingBottom: mobile ? 0 : Theme.S * 6,
  });

  let photoStack = mobile ? (
    <PhotoStack css={photoStackCss}>
      <PhotoStackItem
        asset={Assets.image2}
        top={"10%"}
        bottom={"0%"}
        right={"30%"}
        left={0}
      />
      <PhotoStackItem
        asset={Assets.image1}
        top={"0%"}
        bottom={"10%"}
        right={"0%"}
        left={"60%"}
      />
    </PhotoStack>
  ) : (
    <PhotoStack css={photoStackCss}>
      <PhotoStackItem
        asset={Assets.image2}
        top={0}
        bottom={"0%"}
        right={0}
        left={"20%"}
      />
      <PhotoStackItem
        asset={Assets.image1}
        top={"20%"}
        bottom={"30%"}
        right={"60%"}
        left={0}
      />
      <Asset asset={Assets.feuille1} css={feuilleCss} />
    </PhotoStack>
  );

  return (
    <div css={wrapperCss}>
      <div css={backgroundCss} />
      <Content css={contentCss}>
        {photoStack}
        <Presentation
          css={textsCss}
          title="Plongez dans l'essence Péi : Découvrez les points de vente PAPAM à La Réunion !"
          content="L’essence des traditions ou la curiosité de la découverte ? Les produits issus des plantes réunionnaises sont plus que jamais près de vous. Découvrez le réseau de points de vente de produits à base de PAPAM de La Réunion."
          button={
            <Button
              label="Points de vente"
              color={Theme.colors.white}
              to={Zones.getPath("PointsDeVente")}
            />
          }
          dividerColor={Theme.colors.green5}
        />
      </Content>
    </div>
  );
}
