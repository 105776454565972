/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Key, useCallback, useEffect, useMemo } from "react";
import Css from "src/utilities/Css";
import { Dropdown } from "src/utilities/Dropdown";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";
import DelayedView, {
  useDelayedString,
} from "src/utilities/components/DelayedView";
import Clickable from "../Clickable";
import Typo from "../Typo";
import Field, { useFieldController } from "./Field";
import FieldDropdown from "./FieldDropdown";
import fieldStyles from "./fieldStyles";

type SelectFieldProps<TOption, TKey extends Key> = {
  type?: "text" | "email" | "password";
  label: string;
  value: TKey | null;
  options: Array<TOption> | Promise<Array<TOption>>;
  keyExtractor: (option: TOption) => TKey;
  labelExtractor: (option: TOption) => string;
  placeholder?: string;
  onChange: (value: TKey | null) => any;
  required?: boolean;
};

export default function SelectField<TOption, TKey extends Key>(
  props: SelectFieldProps<TOption, TKey>
) {
  const {
    type = "text",
    value,
    placeholder,
    options,
    onChange,
    keyExtractor,
    labelExtractor,
    ...fieldProps
  } = props;

  const controller = useFieldController();

  const inputCss = css(Css.inputReset, Typos.body, fieldStyles.inputCss);

  const [dropdown, openDropdown] = Dropdown.useSpeaker();

  const optionsPromise = useMemo(
    () => (options instanceof Promise ? options : Promise.resolve(options)),
    [options]
  );

  const displayedValue = useDelayedString(
    optionsPromise,
    (options) => {
      const o = options.find((o) => keyExtractor(o) === value);
      if (!o) return "-";
      else return labelExtractor(o);
    },
    "Chargement en cours...",
    false,
    [value]
  );

  const optionsCss = css({
    display: "flex",
    flexDirection: "column",
  });

  const optionCss = css(Css.clickableReset, {
    paddingBlock: Theme.S,
    paddingInline: Theme.S,
    textAlign: "inherit",
  });

  const onSelectOption = useCallback(
    (option: TOption) => {
      onChange(keyExtractor(option));
      Dropdown.shutUp();
    },
    [onChange]
  );

  useEffect(() => {
    if (dropdown) controller.onFocus();
    else controller.onBlur();
  }, [dropdown]);

  return (
    <Field
      {...fieldProps}
      controller={controller}
      rightIcon="arrow-right"
      stopPropagation
      dropdown={
        <FieldDropdown visible={dropdown}>
          <DelayedView promise={optionsPromise}>
            {(options) => {
              return (
                <div css={optionsCss}>
                  {options.map((o) => (
                    <Clickable
                      css={optionCss}
                      key={keyExtractor(o)}
                      onClick={() => onSelectOption(o)}
                    >
                      <Typo typo="subbody">{labelExtractor(o)}</Typo>
                    </Clickable>
                  ))}
                </div>
              );
            }}
          </DelayedView>
        </FieldDropdown>
      }
    >
      <input
        id={controller.id}
        css={inputCss}
        type={type}
        value={displayedValue}
        onChange={() => {}}
        placeholder={placeholder}
        readOnly
        onFocus={openDropdown}
      />
    </Field>
  );
}
