/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropsWithCss from "src/utilities/PropsWithCss";

import { TypoDescriptors, TypoName } from "src/utilities/Typos";
import "./Papam-Pei-v1.0/style.css";

export type IconProps = PropsWithCss<{
  name: string;
  color?: string;
  rotate?: string;
}> &
  IconSizingProps;

type IconSizingProps = { typo?: TypoName } | { size: number };

export default function Icon(props: IconProps) {
  const { name, color, rotate, className } = props;

  let size: number;
  if ("size" in props) size = props.size;
  else {
    const typo = "typo" in props ? props.typo || "body" : "body";
    size = TypoDescriptors[typo].height;
  }

  const style = css({
    fontSize: size,
    lineHeight: size + "px",
    transform: rotate ? `rotate(${rotate})` : undefined,
    transition: "transform 200ms",
    color,
  });

  return <span className={`papampei-${name} ${className}`} css={style} />;
}
