/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Button from "src/components/Button";
import Content from "src/components/Content";
import { PhotoStack, PhotoStackItem } from "src/components/PhotoStack";
import Presentation from "src/components/Presentation";
import Assets from "src/utilities/Assets";
import PropsWithCss from "src/utilities/PropsWithCss";
import Theme from "src/utilities/Theme";
import Zones from "src/utilities/Zones";
import useResponsive from "src/utilities/useResponsive";

export default function PointsDeVente(props: PropsWithCss) {
  const mobile = useResponsive({
    800: true,
    bigger: false,
  });

  const wrapperCss = css({
    position: "relative",
    paddingBlock: Theme.S * 6,
  });

  const backgroundCss = css({
    position: "absolute",
    top: Theme.S * -10,
    bottom: Theme.S * 10,
    left: 0,
    right: 0,
    zIndex: -1,
  });

  const contentCss = mobile
    ? css({
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: Theme.S * 2,
      })
    : css({
        display: "grid",
        gridTemplateColumns: "40% 1fr",
        gap: Theme.S * 6,
      });

  const photoStackCss = css({
    height: mobile ? "30vh" : "70vh",
  });

  const textsCss = css({
    paddingBottom: mobile ? 0 : Theme.S * 6,
  });

  let photoStack = mobile ? (
    <PhotoStack css={photoStackCss}>
      <PhotoStackItem
        asset={Assets.image3}
        top={"0%"}
        bottom={"00%"}
        right={"40%"}
        left={0}
        css={css({ border: `15px solid white` })}
      />
      <PhotoStackItem
        asset={Assets.image4}
        top={"20%"}
        bottom={"10%"}
        right={0}
        left={"40%"}
      />
    </PhotoStack>
  ) : (
    <PhotoStack css={photoStackCss}>
      <PhotoStackItem
        asset={Assets.image3}
        top={"0%"}
        bottom={"00%"}
        right={"40%"}
        left={0}
        css={css({ border: `15px solid white` })}
      />
      <PhotoStackItem
        asset={Assets.image4}
        top={"20%"}
        bottom={"10%"}
        right={0}
        left={"40%"}
      />
      s
    </PhotoStack>
  );

  const presentationNode = (
    <Presentation
      css={textsCss}
      title="Les Racines de l'Île : Explorez l'univers des plantes aromatiques et médicinales de La Réunion dans nos articles"
      content="Que vous soyez amateur curieux, passionné, ou simplement en recherche d’idées nouvelles pour un projet, cette section est faite pour vous inspirer."
      button={
        <Button
          label="Découvrir nos articles"
          color={Theme.colors.white}
          to={Zones.getPath("Articles")}
        />
      }
      dividerColor={Theme.colors.green5}
    />
  );

  const firstItem = mobile ? photoStack : presentationNode;
  const secondItem = mobile ? presentationNode : photoStack;

  return (
    <div css={wrapperCss}>
      <div css={backgroundCss} />
      <Content css={contentCss}>
        {firstItem}
        {secondItem}
      </Content>
    </div>
  );
}
