/** @jsxImportSource @emotion/react */
import Content from "src/components/Content";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import Services from "src/utilities/Services";
import ContactForm from "../Integrer/ContactForm";

export default function Contact() {
  Services.use().tracking.usePageTracking("Contact");
  return (
    <Page>
      <Intro intro="Contact" title="Remarques et questions" />
      <Content>
        <Spacer scale={6} />
        <ContactForm />
        <Spacer scale={6} />
      </Content>
    </Page>
  );
}
