import { Fragment, ReactNode } from "react";
import RestrictedArea from "src/screens/RestrictedArea";
import Services from "src/utilities/Services";
import Zones from "src/utilities/Zones";

type PageProps = {
  restricted?: boolean;
  noGrowingSpacer?: boolean;
  children?: ReactNode;
};

export default function Page(props: PageProps) {
  const { authentication } = Services.use();
  const isLoggedIn = authentication.useIsLoggedIn();

  Zones.useScrollToHash();

  if (props.restricted && !isLoggedIn) {
    return <RestrictedArea />;
  } else {
    return <Fragment>{props.children}</Fragment>;
  }
}
