/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { sortBy } from "lodash";
import { rgba } from "polished";
import { Outlet } from "react-router-dom";
import Clickable from "src/components/Clickable";
import Icon from "src/components/Icon";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import Assets, { getBackgroundAsset } from "src/utilities/Assets";
import Backgrounded from "src/utilities/components/Backgrounded";
import DelayedView from "src/utilities/components/DelayedView";
import Css from "src/utilities/Css";
import { globalEchangesUpdater } from "src/utilities/globalEchangesUpdater";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Zones from "src/utilities/Zones";

export default function Echanges() {
  const { repository } = Services.use();

  const containerCss = css({
    display: "flex",
    padding: 20,
    flex: 1,
    overflow: "hidden",
  });

  const montagnesCss = css(getBackgroundAsset(Assets.montagnes), {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundSize: "contain",
    backgroundPosition: "bottom center",
    height: 2000,
    zIndex: 2,
    transform: "scaleX(-1)",
  });

  return (
    <Page restricted noGrowingSpacer>
      <Backgrounded
        css={containerCss}
        backgrounds={[<div css={montagnesCss} />]}
      >
        <Topics />
        <Outlet />
      </Backgrounded>
    </Page>
  );
}

function Topics() {
  const { repository } = Services.use();

  const loadAt = globalEchangesUpdater.useValue(() => new Date());

  const topicsPromise = repository.useData(
    async (r) => {
      const topics = await r.getTopics();
      return sortBy(
        topics.data,
        (t) => dayjs(t.attributes.updatedAt).valueOf() * -1
      );
    },
    [loadAt]
  );

  const containerCss = css({
    background: rgba("white", 0.7),
    backdropFilter: "blur(10px)",
    borderRadius: 10,
    flexShrink: 1,
    width: "35%",
    marginRight: 20,
    display: "flex",
    flexDirection: "column",
  });

  const headerCss = css({
    padding: 20,
    display: "flex",
    alignItems: "center",
  });

  const topicsCss = css({
    borderTop: `1px solid ${Theme.colors.green4}`,
    paddingBottom: 10,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  });

  return (
    <div css={containerCss}>
      <div css={headerCss}>
        <Typo typo="subheading">Sujets de discussion</Typo>
        <Spacer grow />
        <Clickable>
          <Icon name="plus" size={40} />
        </Clickable>
      </div>
      <div css={topicsCss}>
        <DelayedView
          promise={topicsPromise}
          grow
          message="Chargement des sujets"
          persistValueDuringReload
        >
          {(topics) => topics.map((t) => <TopicView topic={t} key={t.id} />)}
        </DelayedView>
      </div>
    </div>
  );
}

type TopicViewProps = {
  topic: Model.Topic;
};
function TopicView(props: TopicViewProps) {
  const { topic } = props;

  const currentTopic = Zones.useParam("sujet", null);
  const selected = currentTopic === topic.id.toString();

  const containerCss = css(Css.clickableReset, {
    padding: 10,
    display: "block",
    borderBottom: `1px solid ${Theme.colors.green5}`,
    background: selected ? rgba(Theme.colors.green5, 0.1) : 0,
    color: selected ? Theme.colors.green5 : "black",
  });

  const firstLineCss = css({
    display: "flex",
  });

  const lastMessageCss = css({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  });

  const date =
    topic.attributes.date_dernier_message || topic.attributes.createdAt;

  const lastMessage =
    topic.attributes.dernier_message &&
    topic.attributes.auteur_dernier_message ? (
      <div css={lastMessageCss}>
        <Typo typo="minor" inline>
          {`${topic.attributes.auteur_dernier_message}`}
        </Typo>
        <Typo typo="minor" inline css={css({ opacity: 0.7 })}>
          {` : ${topic.attributes.dernier_message}`}
        </Typo>
      </div>
    ) : null;

  return (
    <Clickable
      css={containerCss}
      to={Zones.getPath("Sujet", { params: { sujet: topic.id.toString() } })}
    >
      <div css={firstLineCss}>
        <Typo typo="account">{topic.attributes.titre}</Typo>
        <Spacer grow />
        <Typo typo="minor" inline css={css({ opacity: 0.7 })}>
          {dayjs(date).fromNow()}
        </Typo>
      </div>

      {lastMessage}
    </Clickable>
  );
}
