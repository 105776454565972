/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "add-to-calendar-button/assets/css/atcb.css";
import dayjs from "dayjs";
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
} from "react";

type TimelineProps = PropsWithChildren<{
  start: string;
  end: string;
  unit: dayjs.QUnitType;
  renderUnit: (d: dayjs.Dayjs) => ReactNode;
  lines?: Partial<Record<dayjs.QUnitType, string>>;
  todayLine?: string;
}>;

export default function Timeline(props: TimelineProps) {
  const { start, end, unit, lines = {}, renderUnit, todayLine } = props;

  const startDate = useMemo(() => {
    return dayjs(start).startOf(unit);
  }, [start, unit]);

  const endDate = useMemo(() => {
    return dayjs(end).endOf(unit);
  }, [end, unit]);

  const extraDate = useMemo(() => {
    return dayjs(end).add(1, unit).startOf(unit);
  }, [end, unit]);

  const linesPosition = useMemo(() => {
    const totalDuration = dayjs
      .duration(endDate.diff(startDate))
      .asMilliseconds();
    const outputs: Array<{ left: number; border: string }> = [];
    Object.entries(lines).map(([int, border]) => {
      const interval = int as dayjs.QUnitType;
      let cursor = startDate.startOf(interval);
      do {
        if (cursor.isBetween(startDate, extraDate, "millisecond", "[]")) {
          const duration = dayjs
            .duration(cursor.diff(startDate))
            .asMilliseconds();
          outputs.push({ left: duration / totalDuration, border });
        }
        cursor = cursor.add(1, interval);
        console.log(cursor.format("LLLL"));
      } while (cursor.isSameOrBefore(extraDate));
    });

    if (todayLine) {
      const duration = dayjs.duration(dayjs().diff(startDate)).asMilliseconds();
      outputs.push({ left: duration / totalDuration, border: todayLine });
    }
    return outputs;
  }, [startDate, endDate, extraDate, lines, todayLine]);

  const bounds = useMemo(
    () => [startDate, endDate] as [dayjs.Dayjs, dayjs.Dayjs],
    [startDate, endDate]
  );

  const items = useMemo(() => {
    const output: Array<dayjs.Dayjs> = [];
    let cursor = startDate;
    do {
      output.push(cursor);
      cursor = cursor.add(1, unit);
    } while (cursor.isBefore(endDate));
    return output;
  }, [startDate, endDate, unit]);

  const timelineCss = css({
    display: "flex",
    flexWrap: "wrap",
  });

  const sectionCss = css({
    display: "flex",
    width: `${100 / items.length}%`,
    flexShrink: 0,
  });

  const sectionLabelCss = css({
    transform: `translateX(-50%)`,
    minWidth: 0,
  });

  const contentCss = css({
    width: "100%",
    position: "relative",
  });

  const linesCss = css({
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    zIndex: 1,
  });

  const lineCss = css({
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 0,
  });

  const childrenCss = css({
    position: "relative",
    zIndex: 2,
  });

  return (
    <TimelineContext.Provider value={bounds}>
      <div css={timelineCss}>
        {items.map((item) => {
          return (
            <div key={item.toISOString()} css={sectionCss}>
              <div css={sectionLabelCss}>{renderUnit(item)}</div>
            </div>
          );
        })}
        <div css={contentCss}>
          <div css={linesCss}>
            {linesPosition.map((line, i) => {
              return (
                <div
                  key={i}
                  css={css(lineCss, {
                    left: `${line.left * 100}%`,
                    borderLeft: line.border,
                  })}
                ></div>
              );
            })}
          </div>
          <div css={childrenCss}>{props.children}</div>
        </div>
      </div>
    </TimelineContext.Provider>
  );
}

const TimelineContext = createContext<[dayjs.Dayjs, dayjs.Dayjs]>([
  dayjs(),
  dayjs(),
] as [dayjs.Dayjs, dayjs.Dayjs]);

type TimelineItemProps = PropsWithChildren<{
  start: string;
  end: string;
}>;

export function TimelineItem(props: TimelineItemProps) {
  const { start, end, children } = props;
  const [min, max] = useContext(TimelineContext);

  const paddingLeft = useMemo(() => {
    const total = dayjs.duration(max.diff(min));
    const totalMs = total.asMilliseconds();
    const beforeStart = dayjs.duration(dayjs(start).diff(min));
    const beforeStartMs = beforeStart.asMilliseconds();
    return beforeStartMs / totalMs;
  }, [start, end, min, max]);

  const paddingRight = useMemo(() => {
    const total = dayjs.duration(max.diff(min));
    const totalMs = total.asMilliseconds();
    const toEnd = dayjs.duration(dayjs(max).diff(end));
    const toEndMs = toEnd.asMilliseconds();
    return toEndMs / totalMs;
  }, [start, end, min, max]);

  const containerCss = css({
    paddingLeft: `${paddingLeft * 100}%`,
    paddingRight: `${paddingRight * 100}%`,
  });

  return <div css={containerCss}>{props.children}</div>;
}
