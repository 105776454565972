/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";
import { AssetInfos, getBackgroundAsset } from "src/utilities/Assets";
import PropsWithCss from "src/utilities/PropsWithCss";

export type PhotoStackProps = PropsWithChildren<PropsWithCss<{}>>;

export function PhotoStack(props: PhotoStackProps) {
  const containerCss = css({
    position: "relative",
  });

  return (
    <div className={props.className} css={containerCss}>
      {props.children}
    </div>
  );
}

export type PhotoStackItemProps = PropsWithCss<
  PropsWithChildren<{
    asset: AssetInfos;
    top: number | string;
    bottom: number | string;
    left: number | string;
    right: number | string;
    noShadow?: boolean;
  }>
>;

export function PhotoStackItem(props: PhotoStackItemProps) {
  const { asset, top, bottom, left, right, noShadow = false } = props;

  const frontImageCss = css(getBackgroundAsset(asset), {
    position: "absolute",
    left,
    top,
    bottom,
    right,
    borderRadius: 20,
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: noShadow ? "none" : `0px 4px 35px rgba(0,0,0,0.1)`,
  });

  return <div className={props.className} css={frontImageCss}></div>;
}
