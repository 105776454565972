/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";
import PropsWithCss from "src/utilities/PropsWithCss";
import Theme from "src/utilities/Theme";
import useResponsive from "src/utilities/useResponsive";

type ContentProps = PropsWithCss<PropsWithChildren<{}>>;

export default function Content(props: ContentProps) {
  const style = useResponsive({
    600: css({
      width: "100%",
      margin: "auto",
      paddingInline: Theme.S * 1,
    }),
    1200: css({
      width: "100%",
      margin: "auto",
      paddingInline: Theme.S * 3,
    }),
    bigger: css({
      width: "100%",
      maxWidth: 1540,
      margin: "auto",
      paddingInline: Theme.S * 6,
    }),
  });

  return (
    <div css={style} className={props.className}>
      {props.children}
    </div>
  );
}
