function string(name: string) {
  const value = process.env[name];
  if (value === undefined) throw new Error(`Missing env. : ${name}`);
  return value;
}

function has(name: string) {
  const value = process.env[name];
  return value !== undefined;
}

const Environment = {
  has,
  string,
};

export default Environment;
