import Page from "src/components/Page";
import Services from "src/utilities/Services";
import ListeArticles from "./ListeArticles";

export default function Articles() {
  Services.use().tracking.usePageTracking("Articles");
  return (
    <Page>
      <ListeArticles />
    </Page>
  );
}
