import Updater from "./Updater";

export default class Authentication {
  private updater = new Updater();
  private jwt: string | null = null;

  constructor(private storageKey: string) {
    this.jwt = localStorage.getItem(storageKey);
  }

  setJwt(input: string | null) {
    this.jwt = input;
    if (input) localStorage.setItem(this.storageKey, input);
    else localStorage.removeItem(this.storageKey);
    this.updater.update();
  }

  useIsLoggedIn() {
    return this.updater.useValue(() => !!this.jwt, []);
  }

  useJwt() {
    return this.updater.useValue(() => {
      if (!this.jwt) throw new Error("No JWT");
      return this.jwt;
    }, []);
  }

  searchJwt() {
    return this.jwt;
  }
}
