/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";
import Theme from "src/utilities/Theme";

type FieldDropdownProps = PropsWithChildren<{
  visible: boolean;
}>;

export default function FieldDropdown(props: FieldDropdownProps) {
  const { visible, children } = props;

  const containerCss = css({
    position: "absolute",
    zIndex: 2,
    top: `calc(100% + ${visible ? Theme.S / 2 : 0}px)`,
    left: 0,
    right: 0,
    opacity: visible ? 1 : 0,
    pointerEvents: visible ? "auto" : "none",
    transition: "opacity 200ms, top 200ms",
    background: "white",
    borderRadius: 8,
    border: `1px solid ${Theme.colors.green3}`,
  });

  return <div css={containerCss}>{children}</div>;
}
