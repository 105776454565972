/** @jsxImportSource @emotion/react */
import React, { ReactNode, useCallback } from "react";
import { Link } from "react-router-dom";
import PropsWithCss from "src/utilities/PropsWithCss";

export type ClickableActionProps = (
  | { submit: true }
  | { href: string; target?: string }
  | { to: string }
  | {}
) & { onClick?: () => any; disabled?: boolean };

export type ClickableProps = PropsWithCss<{
  children?: ReactNode;
}> &
  ClickableActionProps;

export default function Clickable(props: ClickableProps) {
  const { onClick, disabled } = props;

  const onTargetClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (props.disabled) return;
      if (props.onClick) props.onClick();
    },
    [onClick, disabled]
  );

  if ("to" in props && !disabled) {
    return (
      <Link to={props.to} className={props.className} onClick={onTargetClick}>
        {props.children}
      </Link>
    );
  } else if ("href" in props && !disabled) {
    return (
      <a
        href={props.href}
        target={props.target}
        className={props.className}
        onClick={onTargetClick}
      >
        {props.children}
      </a>
    );
  } else if ("submit" in props) {
    return (
      <button
        type="submit"
        className={props.className}
        onClick={onTargetClick}
        disabled={disabled}
      >
        {props.children}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        onClick={onTargetClick}
        className={props.className}
        disabled={disabled}
      >
        {props.children}
      </button>
    );
  }
}
