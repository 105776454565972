/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import BottomLine from "./BottomLine";
import FooterLarge from "./FooterLarge";

export default function Footer() {
  return (
    <Fragment>
      <FooterLarge />
      <BottomLine />
    </Fragment>
  );
}
