/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment } from "react";
import Asset from "src/components/Asset";
import Content from "src/components/Content";
import Presentation from "src/components/Presentation";
import SignInForm from "src/components/SignInForm";
import Assets from "src/utilities/Assets";
import Backgrounded from "src/utilities/components/Backgrounded";
import Theme from "src/utilities/Theme";
import useResponsive from "src/utilities/useResponsive";

export default function RestrictedArea() {
  const containerCss = css({
    background: Theme.colors.green5,
    overflow: "hidden",
  });

  const columnsCss = useResponsive({
    800: css({}),
    bigger: css({
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: Theme.S * 3,
    }),
  });

  const introCss = css({
    position: "relative",
    height: Assets.forme1.height * 0.9,
    zIndex: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: Assets.forme1.height * 0.2,
  });

  const formCss = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <Backgrounded
      css={containerCss}
      backgrounds={[
        <Asset
          asset={Assets.branche}
          css={css({
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: `translate3d(-50%, -50%, 0px)`,
          })}
        />,
        <Asset
          asset={Assets.feuille2}
          css={css({ position: "absolute", right: -30, top: 60 })}
        />,
        <Asset
          asset={Assets.feuille3}
          css={css({ position: "absolute", right: -30, top: 430 })}
        />,
      ]}
    >
      <Content>
        <div css={columnsCss}>
          <div css={introCss}>
            <Backgrounded
              backgrounds={
                <Fragment>
                  <Asset
                    asset={Assets.forme1}
                    css={css({
                      position: "absolute",
                      bottom: -50,
                      right: -50,
                      transform: "scale(1.7)",
                    })}
                  />
                </Fragment>
              }
            >
              <Presentation
                title={"Professionnels"}
                content="Cette section est réservée aux professionnels. Pour y accéder, entrez vos identifiants."
              />
            </Backgrounded>
          </div>
          <div css={formCss}>
            <SignInForm />
          </div>
        </div>
      </Content>
    </Backgrounded>
  );
}
