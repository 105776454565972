import { DependencyList, useCallback } from "react";
import { toast } from "react-toastify";
import getErrorMessage from "./getErrorMessage";
import useBooleanState from "./useBooleanState";

type UseSubmitConf<P extends Array<any>> =
  | ((...p: P) => any)
  | {
      fn: (...p: P) => any;
      successMessage?: string;
    };

export default function useSubmit<P extends Array<any>>(
  config: UseSubmitConf<P>,
  deps: DependencyList = []
) {
  const fn = typeof config === "function" ? config : config.fn;
  const successMessage =
    typeof config === "function" ? null : config.successMessage || null;
  const [pending, setPending] = useBooleanState(false);

  const cb = useCallback(
    async (...p: P) => {
      if (pending) return;
      setPending.toTrue();
      try {
        await fn(...p);
        if (successMessage) toast(successMessage, { type: "success" });
      } catch (err) {
        toast(getErrorMessage(err), { type: "error" });
      } finally {
        setPending.toFalse();
      }
    },
    [...deps, pending]
  );

  return [cb, pending] as const;
}
