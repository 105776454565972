import { css } from "@emotion/react";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";

const inputCss = css(Typos.subbody, {
  paddingBlock: Theme.S,
  paddingInline: Theme.S,
  color: "inherit",
});

const fieldStyles = { inputCss };

export default fieldStyles;
