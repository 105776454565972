import { DependencyList, Fragment, ReactNode, useMemo } from "react";
import useDeferred from "src/utilities/Deferred";

export type DelayedProps<TValue> = {
  promise: Promise<TValue>;
  children: (value: TValue, reloading: boolean) => ReactNode;
  renderIdling: () => ReactNode;
  renderPending: () => ReactNode;
  renderRejected: (error: any) => ReactNode;
  persistValueDuringReload: boolean;
};

export default function Delayed<TValue>(props: DelayedProps<TValue>) {
  const { promise, children, persistValueDuringReload } = props;

  const deferred = useDeferred(promise);

  const renderIdling = props.renderIdling;
  const renderPending = props.renderPending;
  const renderRejected = props.renderRejected;

  if (deferred.state === "idling") {
    return <Fragment>{renderIdling()}</Fragment>;
  } else if (deferred.state === "pending") {
    return <Fragment>{renderPending()}</Fragment>;
  } else if (deferred.state === "resolved") {
    if (deferred.reloading) {
      if (persistValueDuringReload) {
        return <Fragment>{children(deferred.value, true)}</Fragment>;
      } else {
        return <Fragment>{renderPending()}</Fragment>;
      }
    } else {
      return <Fragment>{children(deferred.value, false)}</Fragment>;
    }
  } else {
    return <Fragment>{renderRejected(deferred.error)}</Fragment>;
  }
}

export function useDelayed<TValue, TOutput>(
  promise: Promise<TValue>,
  ifResolved: (v: TValue) => TOutput,
  ifIdling: () => TOutput,
  ifPending: () => TOutput,
  ifRejected: (error: any) => TOutput,
  persistValueDuringReload: boolean,
  deps?: DependencyList
) {
  const deferred = useDeferred(promise);

  return useMemo(() => {
    if (deferred.state === "idling") {
      return ifIdling();
    } else if (deferred.state === "pending") {
      return ifPending();
    } else if (deferred.state === "resolved") {
      if (deferred.reloading) {
        if (persistValueDuringReload) {
          return ifResolved(deferred.value);
        } else {
          return ifPending();
        }
      } else {
        return ifResolved(deferred.value);
      }
    } else {
      return ifRejected(deferred.error);
    }
  }, [deferred, ...(deps || [])]);
}
