import Intro from "src/components/Intro";
import Page from "src/components/Page";
import Services from "src/utilities/Services";
import ListePointsDeVente from "./ListePointsDeVente";

export default function PointsDeVente() {
  Services.use().tracking.usePageTracking("Points de vente");
  return (
    <Page>
      <Intro intro="Papam Péï" title="Découvrez les points de vente" />
      <ListePointsDeVente />
    </Page>
  );
}
