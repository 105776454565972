/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback } from "react";
import Css from "src/utilities/Css";
import Field, { useFieldController } from "./Field";
import fieldStyles from "./fieldStyles";

type TextFieldProps = {
  type?: "text" | "email" | "password";
  label: string;
  value: string | null;
  placeholder?: string;
  onChange: (value: string | null) => any;
  rightIcon?: string;
  textarea?: boolean;
  required?: boolean;
};

export default function TextField(props: TextFieldProps) {
  const {
    type = "text",
    placeholder,
    onChange,
    textarea = false,
    ...fieldProps
  } = props;

  const controller = useFieldController();

  const value = props.value || "";

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const v = e.target.value;
      if (v === "") onChange(null);
      else onChange(v);
    },
    [onChange]
  );

  const inputCss = css(Css.inputReset, fieldStyles.inputCss);

  const textareaCss = css(inputCss, {
    resize: "none",
    height: 200,
  });

  return (
    <Field {...fieldProps} controller={controller}>
      {textarea ? (
        <textarea
          id={controller.id}
          css={textareaCss}
          value={value}
          onChange={onInputChange}
          placeholder={placeholder}
          onFocus={() => controller.onFocus()}
          onBlur={() => controller.onBlur()}
        />
      ) : (
        <input
          id={controller.id}
          css={inputCss}
          type={type}
          value={value}
          onChange={onInputChange}
          placeholder={placeholder}
          onFocus={() => controller.onFocus()}
          onBlur={() => controller.onBlur()}
        />
      )}
    </Field>
  );
}
