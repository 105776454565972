import Content from "src/components/Content";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import { RichContent } from "./Actualite";

export default function Confidentialie() {
  return (
    <Page>
      <Intro title="Politique de confidentialité" />
      <Content>
        <RichContent>{content}</RichContent>
      </Content>
    </Page>
  );
}

const content = `
<h2>Le but de la politique de confidentialité</h2>
<p>
Le but c’est d’informer les utilisateurs de ce site des données personnelles que nous sommes susceptibles de recueillir ainsi que les informations suivantes, le cas échéant :
</p>
<ul>
<li>Quelles sont les données personnelles recueillies</li>
<li>Pour quelle utilisation ces données sont recueillies</li>
<li>Qui a accès aux données recueillies</li>
<li>Quelles sont les droits des utilisateurs du site</li>
<li>Quelle est la politique des cookies du site</li>
</ul>

<p>Cette politique de confidentialité fonctionne parallèlement des conditions générales d’utilisation du site.</p>

<h2>Lois applicables</h2>

<p>Conformément au Règlement général sur la protection des données (RGPD), cette politique de confidentialité est conforme aux règlements suivants :</p>
<p>Les données à caractère personnel doivent être :</p>

<ul>
<li>Traitées de manière licite, loyale et transparente au regard de la personne physique ou morale concernée (licéité, loyauté, transparence) ;</li>
<li>Collectés pour des finalités déterminées, explicites et légitimes, et ne pas être traités ultérieurement d’une manière incompatible avec ces finalités ; le traitement ultérieur à des fins archivistiques dans l’intérêt public, à des fins de recherche scientifique ou historique, ou à des fins statistiques n’est pas considéré, conformément à l’article 89, paragraphe 1, comme incompatible avec les finalités initiales (limitation des finalités) ;</li>
<li>Adéquats, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées (minimisation des données collectées) ;</li>
<li>Exactes et, si nécessaire, tenues à jour ; toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel sui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude) ;</li>
<li>Conservées sous une forme permettant l’identification des personnes concernées pendant une durée n’excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées ; les données à caractère personnel peuvent être conservées pour des durées plus longues dans la mesure où elles sont traitées exclusivement à des fins archivistiques dans l’intérêt public, à des fins de recherche scientifique ou historique, ou à des fins statistiques conformément à l’article 89, paragraphe 1, pour autant que soient mises en œuvre les mesures techniques et organisationnelles appropriées requises par le règlement afin de garantir les droits et libertés de ;a personne concernées (limitation de la conservation) ;</li>
<li>Traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d’origine accidentelle, à l’aide de mesures techniques ou organisationnelles appropriées (intégrité et confidentialité).</li>
</ul>

<p>
Le traitement n’est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie :
</p>

<ul>
<li>La personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques ;</li>
<li>Le traitement est nécessaire, à l’exécution d’un contrat auquel la personne concernée est partie ou à l’exécution de mesures précontractuelles prises à la demande de celle-ci ;</li>
<li>Le traitement est nécessaire au respect d’une obligation légale à laquelle le responsable du traitement est soumis ;</li>
<li>Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne physique ou d’une autre personne physique ;</li>
<li>Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant.</li>
</ul>

<p>Pour les résidents de l’État de Californie, cette politique de confidentialité vise à se conformer à la  California Consumer Privacy Act (CCPA). S’il y a des incohérences entre ce document et la CCPA, la législation de l’État s’appliquera.</p>

<h2>Consentement</h2>
<p>Les utilisateurs conviennent qu’en utilisant notre site, ils consentent à : </p>
<ul>
<li>Les conditions énoncées dans la présente politique de confidentialité et</li>
<li>La collecte, l’utilisation et la conservation des données énumérées dans la présente politique.</li>
</ul>


<h2>Données que nous collectons</h2>
<p>Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir et conserver les renseignements suivants :</p>
<ul>
<li>Données techniques sur l’appareil</li>
<li>Lieu approximatif</li>
</ul>
<h2>Données recueillies de façon non automatique</h2>
<ul>
<li>Prénom et Nom,</li>
<li>Nom de structure (toute personne morale publique ou privée),</li>
<li>Adresse de la structure</li>
<li>Domaine d’activité,</li>
<li>Numéro du système d'identification du répertoire des établissements (SIRET),</li>
<li>E- mail,</li>
<li>Numéro de téléphone,</li>
<li>Adresse de site web,</li>
<li>Informations sur les gammes de produits et de services proposés.</li>
</ul>
<p>Ces données peuvent être recueillies au moyen des méthodes suivantes :</p>
<ul>
<li>Formulaire d’intégration à la liste des points de ventes répertoriées sur le site,</li>
<li>Formulaire de prise de contact pour l’accompagnement de votre projet.</li>
<p>Veuillez noter que nous ne recueillons que les données qui nous aident à attendre le but dans la présente politique de confidentialité. Nous ne recueillons pas de données supplémentaires sans vous en informer au préalable.</p>

<h2>Comment utilisons-nous les données recueillies ?</h2>

<p>Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiquées sur les pages pertinentes de notre site. Nous n’utilisons pas vos données au-delà de ce que nous divulguerons.</p>
<p>Les données que nous recueillons automatiquement sont utilisées aux fins suivantes :</p>
<ul>
<li>Correction de bugs,</li>
<li>Entretient et actualisation des fonctionnalités du site dans le but de proposer un service d’utilisation optimal,</li>
<li>Mesures de fréquentation et de popularité du site.</li>
</ul>


<p>Les données que nous recueillons lorsque l’utilisateur exécute certaines fonctions peuvent être utilisées aux fins suivantes :</p>
<ul>
<li>Accès au service d’intégration à la liste des points de vente des produits à base de plantes aromatiques, à parfum et médicinales de La Réunion,</li>
<li>Prise de contact avec des structures accompagnatrices des porteurs de projet, en lien avec la promotion, la production, la transformation, la commercialisation de produits à base de plantes aromatiques, à parfum et médicinales de La Réunion.</li>
</ul>

<h2>Avec qui nous partageons les données recueillies ?</h2>

<h3>Employés et associés</h3>
<p>Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique de confidentialité.</p>

<h3>Tierces parties</h3>
<p>Les tiers ne seront pas en mesure d’accéder aux données des utilisateurs au-delà de ce qui est raisonnablement nécessaire pour atteindre l’objectif donné. Par ailleurs, nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf dans les cas suivants :</p>
<ul>
<li>Si la loi l’exige,</li>
<li>Si elle est requise pour toute procédure judiciaire,</li>
<li>Pour prouver ou protéger nos droits légaux.</li>
</ul>

<p>Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n’avons pas de contrôle sur leurs politiques et pratiques de quelque nature que ce soit.</p>


<h2>Combien de temps sont stockées les données recueillies ?</h2>
<p>Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les fins pour lesquelles elles sont recueillies.</p>
<h2>Comment nous protégeons vos données personnelles ?</h2>
<p>Toutes les données stockées dans notre système sont sécurisées et ne sont accessibles qu’au personnel en charge de la gestion de la demande de service. Ce personnel est lié par des accords de confidentialité stricts et une violation de cet accord entraînerait des sanctions.</p>
<p>Alors que nous prenons toutes les précautions raisonnables pour nous assurer que les données d’utilisateur sont sécurisées et que les utilisateurs sont protégés, il reste toujours du risque de préjudice. L’Internet en sa totalité peut être, parfois, peu sûr et par conséquence nous sommes incapables de garantir la sécurité des données au-delà de ce qui est raisonnablement pratique.</p>
<h2>Mineurs</h2>
<p>Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal pour que leurs données soient recueillies, traitées et utilisées.</p>


<h2>Vos droits en tant qu’utilisateur</h2>
<p>En vertu de la RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées :</p>
<ul>
<li>Droit à l’accès</li>
<li>Droit de rectification</li>
<li>Droit à l’effacement</li>
<li>Droit de restreindre le traitement</li>
<li>Droit à la portabilité des données</li>
<li>Droit d’objection</li>
</ul>
<p>Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art. 12-23) du RGPD.</p>
<h2>Comment modifier, supprimer ou contester les données recueillies ?</h2>
<p>Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre, veuillez nous communiquer votre demande précise ici : contact@papampei.fr</p>



<h2>Politique sur les cookies</h2>
<p>Un « cookie » est un petit fichier, stocké sur le dispositif de stockage de la machine de l’utilisateur du site. Son but est de recueillir des données relatives aux habitudes de navigation de l’utilisateur ?
Nous utilisons les types de cookies suivants sur notre site :</p>
<h3>Cookies fonctionnels</h3>
<p>Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu’elles soient sauvegardées pour vos prochaines visites.
</p>
<h3>Cookies analytiques</h3>
<p>Nous les utilisons pour améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et sur lequel vous vous accrochez en utilisant notre site. Dans votre navigateur Internet, vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis, ou bien désactiver intégralement la transmission de cookies. Notez que cela peut toutefois diminuer la qualité de l’expérience d’utilisation de notre site.</p>


<h2>Modifications</h2>
<p>Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité avec la loi et de tenir compte de tout changement de notre processus de collecte de données. Nous recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s’assure qu’ils soient informés de toute mis à jour. Au besoin, nous pouvons informer les utilisateurs par tout moyen ou canal de diffusion de l’information pertinent, des changements apportés à cette politique.</p>
<h2>Contact</h2>
<p>Si vous avez des questions à nous poser, n’hésitez pas à nous écrire : contact@papampei.fr</p>

<h2>Date d’entrée en vigueur</h2>
<p>18/05/2023</p>


`;
