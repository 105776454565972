/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";
import PropsWithCss from "src/utilities/PropsWithCss";
import Typos, { TypoName } from "src/utilities/Typos";

type TypoProps = PropsWithCss<{
  typo?: TypoName;
  children: ReactNode;
  center?: boolean;
  oneLine?: boolean;
  color?: string;
  inline?: boolean;
  tag?: "p" | "h1" | "h2" | "span";
}>;

export default function Typo(props: TypoProps) {
  const {
    typo = "body",
    oneLine,
    color,
    inline,
    tag: Component = "p",
    className,
  } = props;
  let containerCss = css`
    ${Typos[typo]}
    text-align : ${props.center ? "center" : undefined};
    color: ${color};
    display: ${inline ? "inline" : "block"};
  `;

  if (oneLine) {
    containerCss = css`
      ${containerCss}
      white-space : nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }

  const children = oneLine ? props.children || "\u00A0" : props.children;

  return (
    <Component css={containerCss} className={className}>
      {children}
    </Component>
  );
}
