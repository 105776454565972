/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { rgba } from "polished";
import { Fragment } from "react";
import Content from "src/components/Content";
import Icon from "src/components/Icon";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import DelayedView from "src/utilities/components/DelayedView";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";
import useResponsive from "src/utilities/useResponsive";
import Zones from "src/utilities/Zones";

export default function Actualite() {
  const { tracking, repository } = Services.use();

  const id = parseInt(Zones.useParam("actualite"));
  tracking.usePageTracking("Actualité", { actualite: id });

  const actualite = repository.useData((r) => r.getOneNews(id), [id]);

  const wrapperCss = css({
    background: Theme.colors.green5,
    borderRadius: 6,
    marginBlock: Theme.S * 2,
    color: Theme.colors.white,
    overflow: "hidden",
  });

  const headingCss = css({
    padding: Theme.S * 2,
  });

  const imageCss = css({
    width: "100%",
    maxHeight: "50vh",
    objectFit: "cover",
    display: "block",
  });

  const contentCss = css({
    background: "white",
    padding: Theme.S * 2,
    color: "black",
  });

  const infosCss = useResponsive({
    600: css({}),
    bigger: css({ display: "grid", gridTemplateColumns: "1fr 1fr" }),
  });

  const eventCss = css({
    background: Theme.colors.green4,
    borderRadius: 6,
    padding: Theme.S,
    margin: Theme.S * 2,
    marginTop: 0,
    display: "flex",
    alignItems: "center",
  });

  return (
    <DelayedView promise={actualite} message="Patientez...">
      {(news) => (
        <Fragment>
          <Content>
            <div css={wrapperCss}>
              <Typo typo="heading" tag="h1" css={headingCss}>
                {news.attributes.title}
              </Typo>
              <div css={infosCss}>
                {news.attributes.date ? (
                  <div css={eventCss}>
                    <Icon name="calendar" color={Theme.colors.yellow} />
                    <Spacer scale={0.5} />
                    <Typo typo="button">
                      {dayjs(news.attributes.date).format("LL")}
                    </Typo>
                  </div>
                ) : null}
                {news.attributes.place ? (
                  <div css={eventCss}>
                    <Icon name="pin" color={Theme.colors.yellow} />
                    <Spacer scale={0.5} />
                    <Typo typo="button">{news.attributes.place}</Typo>
                  </div>
                ) : null}
              </div>
              <img
                src={news.attributes.cover.data.attributes.url}
                alt={news.attributes.cover.data.attributes.alternativeText}
                css={imageCss}
              />
              <div css={contentCss}>
                <RichContent>{news.attributes.content}</RichContent>
              </div>
            </div>
          </Content>
        </Fragment>
      )}
    </DelayedView>
  );
}

type ActualiteHeaderProps = {
  actualite: Model.Actualite;
};

function ActualiteHeader(props: ActualiteHeaderProps) {
  const { actualite } = props;
  const { repository } = Services.use();

  const imageCss = css({
    height: 400,
    backgroundImage: `url(${repository.api.getUri({
      url: actualite.attributes.cover.data.attributes.url,
    })})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  });

  return (
    <div>
      <Typo tag="h1" typo="heading" center>
        {actualite.attributes.title}
      </Typo>
      <div css={imageCss} />
    </div>
  );
}

type RichContentProps = {
  containerRef?: React.RefObject<HTMLDivElement>;
  children: string;
};

export function RichContent(props: RichContentProps) {
  const style = css(Typos.body, {
    strong: css({ fontWeight: 600 }),
    i: css({ fontStyle: "italic" }),
    ul: css({
      paddingLeft: 0,
      listStylePosition: "inside",
      listStyleType: "none",
      "li:before": {
        content: "'➜ '",
      },
    }),
    ol: css({
      paddingLeft: 0,
      listStylePosition: "inside",
      listStyleType: "decimal",
    }),
    figure: css({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }),
    p: css(Typos.body, {
      paddingBlock: 10,
    }),
    h2: css(Typos.heading, {
      marginBlock: 30,
      paddingLeft: 20,
      color: Theme.colors.green5,
      borderLeft: `10px solid ${Theme.colors.green5}`,
    }),
    h3: css(Typos.section, { marginBlock: 20, color: Theme.colors.yellow }),
    "figure, img": {
      display: "flex",
      "&.image": css({
        marginLeft: "auto",
        marginRight: "auto",
      }),
      "&.image-style-block-align-left": css({
        marginLeft: "0px",
        marginRight: "auto",
      }),
      "&.image-style-block-align-right": css({
        marginLeft: "auto",
        marginRight: "0px",
      }),
      "&.image-style-align-left": css({
        marginLeft: "0px",
        marginRight: "0px",
        float: "left",
      }),
      "&.image-style-align-right": css({
        marginLeft: "0px",
        marginRight: "0px",
        float: "right",
      }),
    },
    table: css({
      border: `1px solid black`,
      borderCollapse: "collapse",
      "td, th": css({
        border: `1px solid black`,
        padding: 5,
      }),
      th: css(Typos.subbodyBold, {
        background: rgba(Theme.colors.green5, 0.2),
      }),
    }),
    figcaption: css(Typos.minor, {
      background: rgba(Theme.colors.green5, 0.1),
      color: Theme.colors.green5,
      padding: 10,
      borderRadius: 5,
      marginBlock: 10,
    }),
    blockquote: css(Typos.account, {
      paddingLeft: 20,
      marginInline: 20,
      color: Theme.colors.green5,
      fontStyle: "italic",
      borderLeft: `2px solid ${Theme.colors.green5}`,
    }),
  });

  return (
    <div
      css={style}
      ref={props.containerRef}
      dangerouslySetInnerHTML={{ __html: props.children }}
    ></div>
  );
}
