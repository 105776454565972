/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Theme from "src/utilities/Theme";

type DividerProps = {
  size?: number;
  strenght?: number;
  vertical?: boolean;
  color?: string;
};

function Divider(props: DividerProps) {
  const {
    size,
    strenght = Theme.sizes.dividerStrenght,
    vertical = false,
    color = Theme.colors.yellow,
  } = props;
  const containerCss = css({
    width: vertical ? strenght : size,
    height: vertical ? size : strenght,
    backgroundColor: color,
  });
  return <div css={containerCss} />;
}

export default Divider;
