/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment } from "react";
import Assets from "src/utilities/Assets";
import Theme from "src/utilities/Theme";
import Backgrounded from "src/utilities/components/Backgrounded";
import useResponsive from "src/utilities/useResponsive";
import Asset from "./Asset";
import Content from "./Content";
import Spacer from "./Spacer";
import Typo from "./Typo";

type IntroProps = {
  intro?: string;
  title: string;
  height?: number | string;
  image?: string;
};
export default function Intro(props: IntroProps) {
  const { intro, title, height, image } = props;

  const defaultHeight = useResponsive({ 800: "25vh", bigger: "50vh" });
  const showAllAssets = useResponsive({ 800: false, bigger: true });

  const containerCss = css({
    overflow: "hidden",
  });

  const contentCss = css({
    minHeight: height || defaultHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "70%",
    paddingBlock: 30,
  });

  const backgrounds = (
    <Fragment>
      {image ? (
        <Fragment>
          <img
            src={image}
            css={css({
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            })}
            alt=""
          />
          <div
            css={css({
              position: "absolute",
              background: rgba(Theme.colors.green5, 0.6),
              width: "100%",
              height: "100%",
            })}
          />
        </Fragment>
      ) : (
        <div
          css={css({
            position: "absolute",
            background: Theme.colors.green5,
            width: "100%",
            height: "100%",
          })}
        />
      )}
      <Asset
        asset={Assets.feuille4}
        css={css({ position: "absolute", top: -100, left: -100 })}
      />
      {showAllAssets ? (
        <Asset
          asset={Assets.taches1}
          css={css({ position: "absolute", top: -10, right: -10 })}
        />
      ) : null}
      {showAllAssets ? (
        <Asset
          asset={Assets.feuille5}
          css={css({ position: "absolute", right: -100, bottom: -200 })}
        />
      ) : null}
      {showAllAssets ? (
        <Asset
          asset={Assets.branche2}
          css={css({ position: "absolute", left: -10, bottom: -10 })}
        />
      ) : null}
    </Fragment>
  );

  return (
    <Backgrounded css={containerCss} backgrounds={backgrounds}>
      <Content css={contentCss}>
        <Typo tag="p" center typo="intro" color={Theme.colors.yellow}>
          {intro}
        </Typo>
        <Spacer />
        <Typo tag="h1" center typo="heading" color={Theme.colors.white}>
          {title}
        </Typo>
      </Content>
    </Backgrounded>
  );
}
