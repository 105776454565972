import { PropsWithChildren, useCallback } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Environment from "./Environment";
import { FrontError } from "./getErrorMessage";

export function CaptchaProvider(props: PropsWithChildren<{}>) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={Environment.string("REACT_APP_RECAPTCHA_CLIENT_KEY")}
      language={"fr"}
    >
      {props.children}
    </GoogleReCaptchaProvider>
  );
}

export function useCaptchaChecker() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  return useCallback(async () => {
    if (!executeRecaptcha)
      throw new FrontError("Impossible de lancer la validation anti-robot");
    try {
      const v = await executeRecaptcha();
      return v;
    } catch (err) {
      throw new FrontError("Impossible de lancer la validation anti-robot");
    }
  }, [executeRecaptcha]);
}
