/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import Icon from "./Icon";
import Spacer from "./Spacer";
import Typo from "./Typo";

type ReadMoreProps = PropsWithChildren<{
  foldedHeight: number;
  toggler?: ReactNode;
  unfolded: boolean;
  gradientHeight?: string;
}>;

export default function ReadMore(props: ReadMoreProps) {
  const { foldedHeight, toggler, unfolded, gradientHeight = "70%" } = props;

  const [unfoldable, setUnfoldable] = useBooleanState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const containerCss = css({
    maxHeight:
      unfolded && contentRef.current
        ? contentRef.current.getBoundingClientRect().height
        : foldedHeight,
    transition: "max-height 300ms",
    overflow: "hidden",
    maskImage:
      unfoldable && !unfolded
        ? `linear-gradient(black ${gradientHeight}, transparent 100%)`
        : undefined,
    maskMode: "alpha",
  });

  useEffect(() => {
    if (!contentRef.current) return;
    const rect = contentRef.current.getBoundingClientRect();
    setUnfoldable.to(rect.height > foldedHeight);
  }, [foldedHeight]);

  return (
    <Fragment>
      <div css={containerCss}>
        <div ref={contentRef}>{props.children}</div>
      </div>
      {unfoldable && toggler ? (
        <Fragment>
          <Spacer />
          {toggler}
        </Fragment>
      ) : null}
    </Fragment>
  );
}

type ReadMoreButtonProps = {
  unfolded: boolean;
  onToggle: () => any;
};

export function ReadMoreButton(props: ReadMoreButtonProps) {
  const { unfolded, onToggle } = props;

  return (
    <div
      onClick={onToggle}
      css={css({ display: "flex", alignItems: "center" })}
    >
      <Icon
        color={Theme.colors.yellow}
        name="arrow-right"
        size={20}
        rotate={unfolded ? "-90deg" : "90deg"}
      />
      <Spacer scale={0.5} />
      <Typo color={Theme.colors.yellow} typo="button">
        {unfolded ? "Replier" : "En savoir plus"}
      </Typo>
    </div>
  );
}
