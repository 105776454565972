/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment } from "react";
import Css from "src/utilities/Css";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import { TypoDescriptors } from "src/utilities/Typos";
import Zones from "src/utilities/Zones";
import useResponsive from "src/utilities/useResponsive";
import Clickable from "../Clickable";
import Content from "../Content";
import Divider from "../Divider";
import Spacer from "../Spacer";
import Typo from "../Typo";

export default function BottomLine() {
  const { authentication } = Services.use();
  const containerCss = css({
    backgroundColor: Theme.colors.grey,
    color: "white",
  });

  const mobile = useResponsive({
    800: true,
    bigger: false,
  });

  const legalCss = css({
    display: "flex",
    flexDirection: mobile ? "column" : "row",
    paddingBlock: Theme.S,
  });

  let divider = mobile ? (
    <Spacer />
  ) : (
    <Fragment>
      <Spacer />
      <Divider
        strenght={1}
        size={TypoDescriptors.body.size}
        vertical
        color={Theme.colors.white}
      />
      <Spacer />
    </Fragment>
  );

  return (
    <div css={containerCss}>
      <Content css={legalCss}>
        <Clickable
          css={Css.clickableReset}
          to={Zones.getPath("MentionsLegales")}
        >
          <Typo typo="subbody">Mentions légales</Typo>
        </Clickable>
        {divider}
        <Clickable
          css={Css.clickableReset}
          to={Zones.getPath("Confidentialite")}
        >
          <Typo typo="subbody">Politique de confidentialité</Typo>
        </Clickable>
        {authentication.useIsLoggedIn() ? (
          <Fragment>
            {divider}
            <Clickable
              css={Css.clickableReset}
              to={Zones.getPath("CharteForum")}
            >
              <Typo typo="subbody">
                Charte éthique de la section "Echanges"
              </Typo>
            </Clickable>
          </Fragment>
        ) : null}

        <Spacer grow />
        <Typo typo="subbody">© PAPAM PÉI 2023</Typo>
      </Content>
    </div>
  );
}
