/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Content from "src/components/Content";
import Form from "src/components/fields/Form";
import TextField from "src/components/fields/TextField";

import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import Services from "src/utilities/Services";
import useSubmit from "src/utilities/useSubmit";
import Zones from "src/utilities/Zones";

export default function MotDePasseOublie() {
  const { repository, authentication } = Services.use();
  const navigate = useNavigate();

  const code = Zones.useQueryParam("code", null);
  const [password, setPassword] = useState<string | null>(null);
  const [passwordAgain, setPasswordAgain] = useState<string | null>(null);

  const [onSubmit] = useSubmit(
    {
      fn: async () => {
        if (!code) throw new Error();
        if (!password) throw new Error();
        if (!passwordAgain) throw new Error();
        await repository.confirmPasswordUpdate(code, password, passwordAgain);
        navigate(Zones.getPath("Professionnels"));
      },
      successMessage:
        "Votre mot de passe a été modifié. Connectez-vous en utilisant votre adresse email et le mot de passe que vous venez de définir.",
    },
    [code, password, passwordAgain]
  );

  return (
    <Page>
      <Content
        css={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Spacer scale={2} />
        <Form
          title="Choisissez un nouveau mot de passe"
          icon="key"
          onSubmit={onSubmit}
        >
          <TextField
            value={password}
            onChange={setPassword}
            label="Mot de passe"
            type="password"
          />
          <Spacer />
          <TextField
            value={passwordAgain}
            onChange={setPasswordAgain}
            label="Saisissez un nouvelle fois le même mot de passe"
            type="password"
          />
        </Form>
      </Content>
      <Spacer scale={12} />
    </Page>
  );
}
