/** @jsxImportSource @emotion/react */
import useResponsive from "src/utilities/useResponsive";
import CarouselDesktop from "./CarouselDesktop";
import CarouselMobile from "./CarouselMobile";

export default function Carousel() {
  const Component = useResponsive({
    800: CarouselMobile,
    bigger: CarouselDesktop,
  });

  return <Component />;
}
