/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import Css from "src/utilities/Css";
import Theme from "src/utilities/Theme";
import Zones from "src/utilities/Zones";
import Clickable from "../Clickable";
import Icon from "../Icon";
import Spacer from "../Spacer";
import Typo from "../Typo";
import Field, { useFieldController } from "./Field";
import fieldStyles from "./fieldStyles";

type ConditionFieldProps = {
  value: boolean;
  onChange: (value: boolean) => any;
};

export default function ConditionField(props: ConditionFieldProps) {
  const { value = false, onChange, ...fieldProps } = props;

  const label = "Politique de confidentialité";
  const controller = useFieldController();

  const inputCss = css(Css.inputReset, fieldStyles.inputCss);

  const containerCss = css(inputCss, {
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
  });

  return (
    <Field
      label={label}
      {...fieldProps}
      controller={controller}
      required={true}
    >
      <Clickable css={containerCss} onClick={() => onChange(!value)}>
        <Icon
          name={value ? "checkbox_checked" : "checkbox"}
          color={Theme.colors.green5}
        />
        <Spacer scale={0.5} />
        <Typo>
          J'accepte que mes données soient traitées conformément à{" "}
          <Link
            to={Zones.getPath("Confidentialite")}
            css={Css.link}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            politique de confidentialité
          </Link>{" "}
          de la plateforme
        </Typo>
      </Clickable>
    </Field>
  );
}
