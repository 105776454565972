/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import dayjs from "dayjs";
import fr from "dayjs/locale/fr";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMemo } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "reset-css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Services from "src/utilities/Services";
import App from "./App";
import Actualite from "./screens/Actualite";
import Decouvrir from "./screens/Articles";
import ArticlePage from "./screens/Articles/Article";
import BaseDeDonnees from "./screens/BaseDeDonnees";
import CharteForum from "./screens/CharteForum";
import Confidentialite from "./screens/Confidentialite";
import Contact from "./screens/Contact";
import DonneesDeMarche from "./screens/DonneesDeMarche";
import Echanges from "./screens/Echanges";
import Espace from "./screens/Espace";
import Evenements from "./screens/Evenements";
import Home from "./screens/Home";
import Integrer from "./screens/Integrer";
import MentionsLegales from "./screens/MentionsLegales";
import MotDePasseOublie from "./screens/MotDePassOublie";
import Plante from "./screens/Plante";
import PointsDeVente from "./screens/PointsDeVente";
import Professionnels from "./screens/Professionnels";
import Sujet from "./screens/Sujet";
import Authentication from "./utilities/Authentication";
import Environment from "./utilities/Environment";
import Repository from "./utilities/Repository";
import Theme from "./utilities/Theme";
import Tracking from "./utilities/Tracking";
import Typos from "./utilities/Typos";
import Zones from "./utilities/Zones";

dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(quarterOfYear);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.locale(fr);

declare module "src/utilities/Services" {
  export interface ServiceList {
    authentication: Authentication;
    repository: Repository;
    tracking: Tracking;
  }
}

export default function Root() {
  const globalStyles = css({
    "*": {
      boxSizing: "border-box",
    },
    html: Typos.body,
    body: { minHeight: "100vh", background: Theme.colors.beige },
    a: {
      textDecoration: "none",
    },
    ul: {
      paddingLeft: 15,
      listStyleType: "circle",
    },
  });

  const services = useMemo(() => {
    const authentication = new Authentication("papampei-token");
    const tracking = new Tracking("REACT_APP_MIXPANEL_TOKEN");
    const repository = new Repository(
      Environment.string("REACT_APP_API_URL"),
      authentication
    );
    return { authentication, repository, tracking };
  }, []);

  return (
    <Services.Provider services={services}>
      <ToastContainer
        position="bottom-center"
        hideProgressBar
        closeOnClick
        transition={Zoom}
      />
      <Global styles={globalStyles} />
      <BrowserRouter>
        <Routes>
          <Route path={Zones.getPattern("Home")} element={<App />}>
            <Route index element={<Home />} />
            <Route
              path={Zones.getPattern("Actualite")}
              element={<Actualite />}
            />
            <Route path={Zones.getPattern("Articles")} element={<Outlet />}>
              <Route index element={<Decouvrir />} />
              <Route
                path={Zones.getPattern("Article")}
                element={<ArticlePage />}
              />
            </Route>
            <Route
              path={Zones.getPattern("PointsDeVente")}
              element={<PointsDeVente />}
            />
            <Route path={Zones.getPattern("Integrer")} element={<Integrer />} />
            <Route path={Zones.getPattern("Contact")} element={<Contact />} />
            <Route
              path={Zones.getPattern("Professionnels")}
              element={<Professionnels />}
            >
              <Route
                path={Zones.getPattern("Evenements")}
                element={<Evenements />}
              />
              <Route
                path={Zones.getPattern("BaseDeDonnees")}
                element={<Outlet />}
              >
                <Route index element={<BaseDeDonnees />} />
                <Route path={Zones.getPattern("Plante")} element={<Plante />} />
              </Route>
              <Route
                path={Zones.getPattern("DonneesDeMarche")}
                element={<DonneesDeMarche />}
              />
              <Route path={Zones.getPattern("Echanges")} element={<Echanges />}>
                <Route path={Zones.getPattern("Sujet")} element={<Sujet />} />
              </Route>
            </Route>
            <Route path={Zones.getPattern("Espace")} element={<Espace />} />
            <Route
              path={Zones.getPattern("MotDePasseOublie")}
              element={<MotDePasseOublie />}
            />
            <Route
              path={Zones.getPattern("MentionsLegales")}
              element={<MentionsLegales />}
            />
            <Route
              path={Zones.getPattern("CharteForum")}
              element={<CharteForum />}
            />
            <Route
              path={Zones.getPattern("Confidentialite")}
              element={<Confidentialite />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Services.Provider>
  );
}
