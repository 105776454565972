/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, PropsWithChildren, ReactNode } from "react";
import Asset from "src/components/Asset";
import Typo from "src/components/Typo";
import Assets from "../Assets";
import Theme from "../Theme";

type ListPlaceholderProps<D> = PropsWithChildren<{
  list: Array<D>;
  ifEmpty?: string | ReactNode;
}>;

export default function ListPlaceholder<D>(props: ListPlaceholderProps<D>) {
  const {
    list,
    ifEmpty = "Cette section est vide pour le moment... Revenez plus tard !",
    children,
  } = props;
  if (list.length === 0) {
    if (typeof ifEmpty === "string") return <EmptyView message={ifEmpty} />;
    else return <Fragment>{ifEmpty}</Fragment>;
  } else {
    return <Fragment>{children}</Fragment>;
  }
}

type EmptyViewProps = {
  message: string;
};

export function EmptyView(props: EmptyViewProps) {
  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBlock: Theme.S * 2,
  });

  return (
    <div css={containerCss}>
      <Asset asset={Assets.cailloux} css={css({ height: 100 })} />
      <Typo center>{props.message}</Typo>
    </div>
  );
}
