import { css } from "@emotion/react";

export type AssetInfos = {
  path1x: string;
  path2x: string;
  path3x: string;
  alt: string;
  width: number;
  height: number;
};

const logo: AssetInfos = {
  path1x: "/assets/Logo@1x.png",
  path2x: "/assets/Logo@2x.png",
  path3x: "/assets/Logo@3x.png",
  alt: "Montagnes, un tisanier et l'inscription Papam Péï",
  width: 258,
  height: 108,
};

const panther: AssetInfos = {
  path1x: "/assets/Panther@1x.png",
  path2x: "/assets/Panther@2x.png",
  path3x: "/assets/Panther@3x.png",
  alt: "Tâches faisant penser à des tâches de panthère",
  width: 311,
  height: 303,
};

const image1: AssetInfos = {
  path1x: "/assets/image1@1x.jpg",
  path2x: "/assets/image1@2x.jpg",
  path3x: "/assets/image1@3x.jpg",
  alt: "Homme portant un tee shirt jaune",
  width: 390,
  height: 506,
};
const image2: AssetInfos = {
  path1x: "/assets/image2@1x.jpg",
  path2x: "/assets/image2@2x.jpg",
  path3x: "/assets/image2@3x.jpg",
  alt: "Ingérdients de tisanne",
  width: 619,
  height: 855,
};

const image3: AssetInfos = {
  path1x: "/assets/image3@1x.jpg",
  path2x: "/assets/image3@2x.png",
  path3x: "/assets/image3@3x.png",
  alt: "Feuille",
  width: 490,
  height: 838,
};

const image4: AssetInfos = {
  path1x: "/assets/image4@1x.jpg",
  path2x: "/assets/image4@2x.jpg",
  path3x: "/assets/image4@3x.jpg",
  alt: "Feuille",
  width: 489,
  height: 580,
};

const feuille1: AssetInfos = {
  path1x: "/assets/feuille1.svg",
  path2x: "/assets/feuille1.svg",
  path3x: "/assets/feuille1.svg",
  alt: "Feuille",
  width: 344,
  height: 399,
};

const feuille2: AssetInfos = {
  path1x: "/assets/feuille2.svg",
  path2x: "/assets/feuille2.svg",
  path3x: "/assets/feuille2.svg",
  alt: "Feuille",
  width: 250,
  height: 276,
};

const feuille3: AssetInfos = {
  path1x: "/assets/feuille3.svg",
  path2x: "/assets/feuille3.svg",
  path3x: "/assets/feuille3.svg",
  alt: "Feuille",
  width: 433,
  height: 515,
};

const feuille4: AssetInfos = {
  path1x: "/assets/feuille4.svg",
  path2x: "/assets/feuille4.svg",
  path3x: "/assets/feuille4.svg",
  alt: "Feuille",
  width: 809,
  height: 824,
};

const feuille5: AssetInfos = {
  path1x: "/assets/feuille5.svg",
  path2x: "/assets/feuille5.svg",
  path3x: "/assets/feuille5.svg",
  alt: "Feuille",
  width: 523,
  height: 522,
};

const montagnes: AssetInfos = {
  path1x: "/assets/montagnes.svg",
  path2x: "/assets/montagnes.svg",
  path3x: "/assets/montagnes.svg",
  alt: "Homme portant un tee shirt jaune",
  width: 1920,
  height: 926,
};

const branche: AssetInfos = {
  path1x: "/assets/branche.svg",
  path2x: "/assets/branche.svg",
  path3x: "/assets/branche.svg",
  alt: "Branche",
  width: 859,
  height: 2277,
};

const branche2: AssetInfos = {
  path1x: "/assets/branche2.svg",
  path2x: "/assets/branche2.svg",
  path3x: "/assets/branche2.svg",
  alt: "Branche",
  width: 215,
  height: 365,
};

const forme1: AssetInfos = {
  path1x: "/assets/forme1.svg",
  path2x: "/assets/forme1.svg",
  path3x: "/assets/forme1.svg",
  alt: "Formes",
  width: 1066,
  height: 1050,
};

const taches1: AssetInfos = {
  path1x: "/assets/taches1.svg",
  path2x: "/assets/taches1.svg",
  path3x: "/assets/taches1.svg",
  alt: "Formes",
  width: 319,
  height: 292,
};

const header: AssetInfos = {
  path1x: "/assets/header.jpg",
  path2x: "/assets/header.jpg",
  path3x: "/assets/header.jpg",
  alt: "Formes",
  width: 1920,
  height: 602,
};

const cailloux: AssetInfos = {
  path1x: "/assets/cailloux.svg",
  path2x: "/assets/cailloux.svg",
  path3x: "/assets/cailloux.svg",
  alt: "Formes",
  width: 285,
  height: 138,
};

const Assets = {
  logo,
  panther,
  image1,
  image2,
  image3,
  image4,
  feuille1,
  feuille2,
  feuille3,
  feuille4,
  feuille5,
  branche,
  forme1,
  montagnes,
  header,
  branche2,
  taches1,
  cailloux,
};

export function getBackgroundAsset(asset: AssetInfos) {
  return css({
    backgroundImage: `image-set(
      url("${asset.path1x}") 1x,
      url("${asset.path2x}") 2x,
      url("${asset.path3x}") 3x)`,
    backgroundSize: `${asset.width}px ${asset.height}px`,
    backgroundRepeat: "no-repeat",
  });
}

export default Assets;
