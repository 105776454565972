/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Clickable from "src/components/Clickable";
import Typo from "src/components/Typo";
import Assets from "src/utilities/Assets";
import Css from "src/utilities/Css";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Zones, { ZoneKey } from "src/utilities/Zones";
import useBooleanState from "src/utilities/useBooleanState";
import Asset from "../Asset";
import Icon from "../Icon";
import Spacer from "../Spacer";

export default function Header() {
  const { authentication } = Services.use();

  const containerCss = css({
    position: "relative",
    zIndex: 1000,
  });

  const topbarCss = css({
    position: "relative",
    zIndex: 2,
    height: Theme.sizes.headerMobileHeight,
    backgroundColor: Theme.colors.white,
    boxShadow: "0px 4px 8px rgba(41,41,41, 0.08)",
  });

  const headerCss = css({
    display: "grid",
    gridTemplateColumns: "80px 1fr 80px",
    gap: Theme.S,
    alignItems: "center",
    height: Theme.sizes.headerMobileHeight,
  });

  const logoCss = css({
    width: "100%",
    height: 80,
    alignSelf: "center",
  });

  const [menu, setMenu] = useBooleanState(false);

  const menuCss = css({
    position: "fixed",
    zIndex: 1,
    top: 80 + Theme.S,
    bottom: menu ? "0vh" : "10vh",
    left: 0,
    right: 0,
    background: Theme.colors.white,
    pointerEvents: menu ? "auto" : "none",
    opacity: menu ? 1 : 0,
    transition: "opacity 300ms, transform 300ms, bottom 300ms",
    transform: `translate3d(0px, ${menu ? "-0px" : "-30px"}, 0px)`,
  });

  const menuItemCss = css({
    padding: Theme.S,
    display: "block",
  });

  const location = useLocation();
  useEffect(() => {
    setMenu.toFalse();
  }, [location]);

  return (
    <div css={containerCss}>
      <div css={topbarCss}>
        <div css={headerCss}>
          <div></div>
          <Clickable to={Zones.getPath("Home")}>
            <Asset asset={Assets.logo} css={logoCss} />
          </Clickable>
          <Clickable onClick={setMenu.toggle} css={Css.clickableReset}>
            <Icon name="menu" size={40} />
          </Clickable>
        </div>
      </div>
      <menu css={menuCss}>
        <Spacer />
        <MenuItem zone="Home" label="Accueil" strict={true} />
        <MenuItem zone="Articles" label="Articles" />
        <MenuItem zone="PointsDeVente" label="Points de vente" />
        <MenuItem zone="Integrer" label="Porteur de projet" />
      </menu>
    </div>
  );
}

type MenuItemProps = {
  zone: ZoneKey;
  label: string;
  strict?: boolean;
};

function MenuItem(props: MenuItemProps) {
  const { zone, label, strict = false } = props;

  const isInRoute = Zones.useIsInRoute(zone);
  const isUnderRoute = Zones.useIsUnderRoute(zone);
  const isSelected = strict ? isInRoute : isUnderRoute;

  let itemCss = css(Css.clickableReset, {
    display: "flex",
    paddingInline: Theme.S * 2,
    paddingBlock: Theme.S,
    alignItems: "center",
    justifyContent: "center",
    transition: "all 200ms",
  });

  if (isSelected) {
    itemCss = css(itemCss, {
      color: Theme.colors.yellow,
    });
  } else {
    itemCss = css(itemCss, {
      "&:hover": {
        color: Theme.colors.yellow,
      },
    });
  }

  return (
    <Clickable css={itemCss} to={Zones.getPath(zone)}>
      <Typo typo="menuItem" oneLine center>
        {label}
      </Typo>
    </Clickable>
  );
}
