/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDelayedString } from "src/utilities/components/DelayedView";
import Css from "src/utilities/Css";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Zones from "src/utilities/Zones";
import Clickable from "../Clickable";
import Icon from "../Icon";
import Spacer from "../Spacer";
import Typo from "../Typo";

export default function MyAccountLink() {
  const { repository } = Services.use();

  const me = repository.useData((r) => r.getMe(), []);

  const text = useDelayedString(
    me,
    (u) => `${u.firstname} ${u.lastname[0] || "."}`
  );

  const containerCss = css(Css.clickableReset, {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <Clickable to={Zones.getPath("Espace")} css={containerCss}>
      <Icon name="user" typo="account" color={Theme.colors.yellow} />
      <Spacer inline />
      <Typo typo="account" inline>
        {text}
      </Typo>
    </Clickable>
  );
}
