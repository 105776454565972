import mixpanel, { Dict } from "mixpanel-browser";
import { useEffect } from "react";
import Environment from "./Environment";

export default class Tracking {
  private enabled: boolean;

  constructor(env: string) {
    this.enabled = Environment.has(env);
    if (this.enabled) {
      mixpanel.init(Environment.string(env), { debug: true });
    }
  }

  usePageTracking(page_name: string, extras: Dict = {}) {
    useEffect(() => {
      mixpanel.track("visit", { page_name, ...extras });
    }, []);
  }

  externalClick(target_website: string) {
    mixpanel.track("external_link_visited", { target_website });
  }
}
