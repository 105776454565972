/** @jsxImportSource @emotion/react */
import { Fragment, useMemo } from "react";
import Content from "src/components/Content";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import DelayedView from "src/utilities/components/DelayedView";
import Services from "src/utilities/Services";
import Zones from "src/utilities/Zones";

export default function Plante() {
  const { repository } = Services.use();

  const id = Zones.useParam("plante");

  const plante = useMemo(() => {
    return repository.getPlant(id);
  }, [id]);

  return (
    <Page restricted>
      <DelayedView promise={plante}>
        {(plante) => (
          <Fragment>
            <Intro title={plante.attributes.nom} height={250} />
            <Content></Content>
          </Fragment>
        )}
      </DelayedView>
    </Page>
  );
}
