/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "add-to-calendar-button/assets/css/atcb.css";
import dayjs from "dayjs";
import { maxBy, mean, minBy } from "lodash";
import { rgba } from "polished";
import { Fragment, useMemo } from "react";
import Asset from "src/components/Asset";
import Content from "src/components/Content";
import Icon from "src/components/Icon";
import Intersperse from "src/components/Intersperse";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import ReadMore, { ReadMoreButton } from "src/components/ReadMore";
import Spacer from "src/components/Spacer";
import Timeline, { TimelineItem } from "src/components/Timeline";
import Typo from "src/components/Typo";
import Assets from "src/utilities/Assets";
import Backgrounded from "src/utilities/components/Backgrounded";
import DelayedView from "src/utilities/components/DelayedView";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import useBooleanState from "src/utilities/useBooleanState";
import useResponsive from "src/utilities/useResponsive";
import { RichContent } from "./Actualite";

export default function Evenements() {
  const { repository } = Services.use();

  const actions = repository.useData((r) => r.getActions());
  const showGantt = useResponsive({ 700: false, bigger: true });

  const containerCss = css({
    background: "white",
    borderRadius: 20,
    paddingBlock: 20,
    paddingInline: 60,
  });

  const actionsCss = css({
    paddingBlock: 10,
  });

  return (
    <Page restricted>
      <Intro title="Calendrier" intro="Intranet" />
      <Content>
        <Spacer scale={2} />
        {showGantt ? (
          <div css={containerCss}>
            <DelayedView promise={actions}>
              {(actions) => {
                if (actions.length === 0) return <Fragment />;
                const minDate = minBy(
                  actions.map((a) => dayjs(a.attributes.start)),
                  (d) => d.valueOf()
                ) as dayjs.Dayjs;
                const maxDate = maxBy(
                  actions.map((a) => dayjs(a.attributes.end)),
                  (d) => d.valueOf()
                ) as dayjs.Dayjs;
                return (
                  <Fragment>
                    <Timeline
                      start={minDate.toISOString()}
                      end={maxDate.toISOString()}
                      unit="year"
                      renderUnit={(d) => (
                        <Typo
                          typo="subbodyBold"
                          css={css({ paddingBottom: 5, opacity: 0.5 })}
                        >
                          {d.format("YYYY")}
                        </Typo>
                      )}
                      lines={{
                        month: `1px dashed ${rgba("black", 0.2)}`,
                        quarter: `1px solid ${rgba("black", 0.2)}`,
                        year: `1px solid ${rgba("black", 1)}`,
                      }}
                      todayLine="2px solid red"
                    >
                      <div css={actionsCss}>
                        {actions.map((a) => (
                          <ActionLine action={a} key={a.id} />
                        ))}
                      </div>
                    </Timeline>
                  </Fragment>
                );
              }}
            </DelayedView>
          </div>
        ) : null}
        <Spacer scale={2} />
        <DelayedView promise={actions}>
          {(actions) => (
            <Intersperse between={() => <Spacer />}>
              {actions.map((a) => (
                <ActionBlock action={a} key={a.id} />
              ))}
            </Intersperse>
          )}
        </DelayedView>
        <Spacer scale={3} />
      </Content>
    </Page>
  );
}

function textBorder(size: number, color: string) {
  return css({
    textShadow: [
      `${size * -1}px ${size * -1}px 0px ${color}`,
      `0px ${size * -1}px 0px ${color}`,
      `${size}px ${size * -1}px 0px ${color}`,
      `${size * -1}px 0px 0px ${color}`,
      `${size}px 0px 0px ${color}`,
      `${size * -1}px ${size}px 0px ${color}`,
      `0px ${size}px 0px ${color}`,
      `${size}px ${size}px 0px ${color}`,
    ].join(", "),
  });
}

type ActionLineProps = {
  action: Model.Action;
};

function ActionLine(props: ActionLineProps) {
  const { action } = props;

  const actionNameCss = css(
    {
      paddingBottom: 2,
      color: Theme.colors.yellow,
    },
    textBorder(2, "white")
  );

  const actionLineCss = css({
    height: 8,
    borderRadius: 4,
    background: Theme.colors.yellow,
    paddingBlock: 2,
    outline: `2px solid white`,
    position: "relative",
  });

  const progressionLineCss = css({
    height: 4,
    background: "white",
    borderRadius: 4,
    marginInline: 2,
  });

  const eventCss = css({
    width: 14,
    height: 14,
    background: Theme.colors.green5,
    borderRadius: 10,
    position: "absolute",
    top: -3,
    transform: `translateX(-50%)`,
    zIndex: 2,
  });

  const progression = useMemo(() => {
    return mean(
      action.attributes.etapes.data.map((e) => e.attributes.progression)
    );
  }, []);

  const actionDuration = dayjs
    .duration(dayjs(action.attributes.end).diff(action.attributes.start))
    .asMilliseconds();

  return (
    <TimelineItem start={action.attributes.start} end={action.attributes.end}>
      <Typo typo="subbodyBold" css={actionNameCss}>
        {action.attributes.label}
      </Typo>
      <div css={actionLineCss}>
        <div
          css={css(
            progressionLineCss,
            css({ width: `calc(${progression}% - 4px)` })
          )}
        ></div>
        {action.attributes.evenements.data.map((e) => {
          const fromStartDuration = dayjs
            .duration(dayjs(e.attributes.date).diff(action.attributes.start))
            .asMilliseconds();
          const left = `${(fromStartDuration / actionDuration) * 100}%`;
          return <div css={css(eventCss, css({ left }))}></div>;
        })}
      </div>
    </TimelineItem>
  );
}

type ActionBlockProps = {
  action: Model.Action;
};

function ActionBlock(props: ActionBlockProps) {
  const { action } = props;
  const { label, description, start, end } = action.attributes;
  const etapes = action.attributes.etapes.data;

  const stackDisplay = useResponsive({ 700: true, bigger: false });

  const progression = useMemo(() => {
    return mean(
      action.attributes.etapes.data.map((e) => e.attributes.progression)
    );
  }, []);

  const containerCss = css({
    display: "flex",
    flexDirection: stackDisplay ? "column" : "row",
    background: "white",
    borderRadius: 20,
  });

  const leftCss = css({
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: stackDisplay ? 0 : 20,
    borderTopRightRadius: stackDisplay ? 20 : 0,
    background: Theme.colors.green5,
    color: "white",
    width: stackDisplay ? "100%" : "40%",
    flexShrink: 0,
    padding: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
  });
  const infosCss = css({});
  const progressionCss = css({});
  const rightCss = css({
    flex: 1,
    padding: 40,
  });

  const [more, setMore] = useBooleanState(false);

  return (
    <div css={containerCss}>
      <Backgrounded
        backgrounds={[
          <Asset
            asset={Assets.panther}
            css={css({ position: "absolute", top: 0, left: 0 })}
          />,
        ]}
        css={leftCss}
      >
        <div css={infosCss}>
          <Typo typo="section">{label}</Typo>
          <Typo typo="subbody">
            Du {dayjs(start).format("LL")} au {dayjs(end).format("LL")}
          </Typo>
        </div>
        <div css={progressionCss}>
          <Typo typo="subbody">Avancement</Typo>
          <Typo typo="heading">{Math.round(progression)}%</Typo>
        </div>
      </Backgrounded>
      <div css={rightCss}>
        <Intersperse between={() => <Spacer />}>
          {description ? (
            <ReadMore
              unfolded={more}
              foldedHeight={50}
              toggler={
                <ReadMoreButton unfolded={more} onToggle={setMore.toggle} />
              }
            >
              <RichContent>{description}</RichContent>
            </ReadMore>
          ) : null}
          <Intersperse between={() => <Spacer />}>
            {etapes.map((etape) => (
              <StepBlock step={etape} key={etape.id} />
            ))}
          </Intersperse>
        </Intersperse>
      </div>
    </div>
  );
}

type StepBlockProps = {
  step: Model.Step;
};

function StepBlock(props: StepBlockProps) {
  const { step } = props;

  const [unfolded, setUnfolded] = useBooleanState(false);
  const containerCss = css({
    display: "flex",
  });
  const arrowCss = css({
    flexShrink: 1,
    padding: 10,
    paddingLeft: 0,
  });
  const iconCss = css({
    transform: `rotate(${unfolded ? "90deg" : "0deg"})`,
  });

  const contentCss = css({});

  const presentationCss = css({
    paddingBlock: 10,
    display: "flex",
    alignItems: "center",
  });

  const badgeCss = css({
    color: "white",
    paddingInline: 7,
    paddingBlock: 3,
    borderRadius: 20,
  });

  let progressionText: string;
  let progressionColor: string;
  if (step.attributes.progression === 0) {
    progressionText = "A venir";
    progressionColor = "grey";
  } else if (step.attributes.progression === 100) {
    progressionText = "Terminé";
    progressionColor = Theme.colors.green5;
  } else {
    progressionText = `En cours - ${Math.round(step.attributes.progression)}%`;
    progressionColor = Theme.colors.yellow;
  }

  return (
    <div css={containerCss}>
      <div css={arrowCss} onClick={setUnfolded.toggle}>
        <Icon name="arrow-right" css={iconCss} typo="body" />
      </div>
      <div css={contentCss}>
        <div css={presentationCss} onClick={setUnfolded.toggle}>
          <Typo>{step.attributes.label}</Typo>
          <Spacer />
          <Typo
            typo="subbodyBold"
            css={css(badgeCss, { backgroundColor: progressionColor })}
          >
            {progressionText}
          </Typo>
        </div>
        <ReadMore unfolded={unfolded} foldedHeight={0}>
          <RichContent>{step.attributes.detail}</RichContent>
        </ReadMore>
      </div>
    </div>
  );
}
