/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { ReactNode, useCallback, useRef } from "react";
import Slider from "react-slick";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import Presentation from "src/components/Presentation";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import DelayedView from "src/utilities/components/DelayedView";
import Loading from "src/utilities/components/Loading";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Zones from "src/utilities/Zones";

export default function CarouselDesktop() {
  const { repository } = Services.use();

  const actualites = repository.useData((r) => r.getNewsInCarousel());

  const containerCss = css(heightCss, {});

  const pendingViewCss = css(heightCss, {
    background: Theme.colors.green5,
    color: Theme.colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const ref = useRef<Slider>(null);

  return (
    <div css={containerCss}>
      <DelayedView
        promise={actualites}
        renderPending={() => {
          return (
            <div css={pendingViewCss}>
              <Loading message="Chargement des actualités en cours..." />
            </div>
          );
        }}
      >
        {(actualites) => {
          return (
            <Slider dots={false} arrows={false} ref={ref} infinite={false}>
              {actualites.map((a, i) => {
                return (
                  <Slide
                    actualite={a}
                    key={a.id}
                    slider={ref}
                    index={i}
                    total={actualites.length}
                  />
                );
              })}
            </Slider>
          );
        }}
      </DelayedView>
    </div>
  );
}

type SlideProps = {
  index: number;
  total: number;
  actualite: Model.Actualite;
  slider: React.RefObject<Slider>;
};

function Slide(props: SlideProps) {
  const { repository } = Services.use();
  const { actualite, slider, index, total } = props;
  const imageWidth = `calc((100vw - ${Theme.sizes.contentWidth}px) / 2 + ${Theme.sizes.contentWidth}px * 0.6)`;
  const textsWidth = `calc((100vw - ${Theme.sizes.contentWidth}px) / 2 + ${Theme.sizes.contentWidth}px * 0.4)`;
  const contentWidth = Theme.sizes.contentWidth * 0.4;
  const slideCss = css(heightCss, {
    display: "flex",
    background: Theme.colors.green5,
  });

  const imageSide = css(heightCss, {
    width: imageWidth,
    objectFit: "cover",
  });

  const textsSide = css({
    width: textsWidth,
    color: Theme.colors.white,
    display: "flex",
  });

  const contentZone = css({
    width: contentWidth,
    display: "flex",
    flexDirection: "column",
    paddingInline: Theme.S * 6,
  });

  const actualiteZone = css({
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingTop: Theme.S * 6,
  });
  const navigationZone = css({
    paddingBlock: Theme.S * 6,
    display: "flex",
    alignItems: "center",
  });
  const counterCss = css({
    paddingInline: Theme.S * 2,
  });
  const dateAndPlaceCss = css({
    display: "flex",
    marginBottom: Theme.S,
  });
  const dateOrLocationCss = css({
    display: "flex",
    alignItems: "center",
    marginRight: Theme.S * 2,
  });
  const onPrevious = useCallback(() => {
    if (!slider.current) return;
    slider.current.slickPrev();
  }, []);
  const onNext = useCallback(() => {
    if (!slider.current) return;
    slider.current.slickNext();
  }, []);
  let dateAndPlaceNode: ReactNode = null;
  if (actualite.attributes.place || actualite.attributes.date) {
    dateAndPlaceNode = (
      <div css={dateAndPlaceCss}>
        {actualite.attributes.date ? (
          <div css={dateOrLocationCss}>
            <Icon name="calendar" color={Theme.colors.yellow} />
            <Spacer scale={0.5} />
            <Typo typo="button">
              {dayjs(actualite.attributes.date).format("LL")}
            </Typo>
          </div>
        ) : null}
        {actualite.attributes.place ? (
          <div css={dateOrLocationCss}>
            <Icon name="pin" color={Theme.colors.yellow} />
            <Spacer scale={0.5} />
            <Typo typo="button">{actualite.attributes.place}</Typo>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div css={slideCss}>
      <img
        css={imageSide}
        src={actualite.attributes.cover.data.attributes.url}
        alt={actualite.attributes.cover.data.attributes.alternativeText}
      />
      <div css={textsSide}>
        <div css={contentZone}>
          <div css={actualiteZone}>
            {dateAndPlaceNode}
            <Presentation
              title={actualite.attributes.title}
              button={
                <Button
                  label="En savoir plus"
                  to={Zones.getPath("Actualite", {
                    params: { actualite: actualite.id.toString() },
                  })}
                />
              }
            />
          </div>
          <div css={navigationZone}>
            <Button icon="arrow-left" onClick={onPrevious} />
            <div css={counterCss}>
              <Typo typo="button">{` ${index + 1} / ${total}`}</Typo>
            </div>
            <Button icon="arrow-right" onClick={onNext} />
          </div>
        </div>
      </div>
    </div>
  );
}

const heightCss = css({
  height: `calc(100vh - ${Theme.sizes.headerHeight}px - 100px)`,
  minHeight: 400,
});
