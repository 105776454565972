const Theme = {
  colors: {
    white: "white",
    black: "black",
    yellow: "#D59C42",
    green5: "#034448",
    green4: "#2B6C70",
    green3: "#009070",
    beige: "#EAE0DB",
    grey: "#282825",
    lightgrey: "#999999",
  },
  S: 15,
  sizes: {
    headerHeight: 150,
    headerMobileHeight: 100,
    contentWidth: 1540,
    dividerStrenght: 5,
  },
  breakpoint: 800,
};

export default Theme;
